/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import "./Highlight.css";
import Folios from "../../../../../app/pages/Folios/Folios";
import Box from "@mui/material/Box";
import AddServiceModal from "../../../../../app/pages/Folios/FolioModals/AddServiceModal";
import EditCustomerModal from "../../../../../app/pages/Folios/FolioModals/EditCustomerModal";
import "react-responsive-modal/styles.css";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import * as FaIcons from "react-icons/fa";
import * as MdIcons from "react-icons/md";
import {
  Drawer,
  TextField,
  Tooltip,
  Button,
  CircularProgress,
  Fade,
  Backdrop,
  Badge,
} from "@mui/material";
import "../../../../../app/pages/AccessControl/Views/AccessControlModal.css";
import { Card } from "react-bootstrap";
import { AiOutlineMail } from "react-icons/ai";
import { AiFillPhone } from "react-icons/ai";
import { AiOutlineCalendar } from "react-icons/ai";
import { ELASTIC_URL, ELASTIC_TOKEN } from "../../../../../app/api/config";
import { BsSearch, BsFillChatLeftDotsFill } from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getRandomValues } from "crypto";
import {
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../../../../app/api";
import { toast } from "material-react-toastify";
import HighLighter from "./Highlighter";
import * as BsIcons from "react-icons/bs";
import moment from "moment";
import Freeze from "../../../../../app/Utils/Freeze";
import Lottie from "react-lottie";
import successAnimationData from "../../../../../../src/app/pages/CalendarPage/Views/lotties/celebration.json";
import { getCsmDetails } from "../../extras/dropdowns/apis/NotificationAPI";
import { getAllImagesAndUrl } from "../../../../../app/pages/BookingDashboard/apis/BrandingModalApi";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import { withStyles } from "@material-ui/core/styles";
import i18n from "../../../../../app/modules/Auth/pages/i18n";
import { MdOutlineRateReview } from "react-icons/md";
import { useTranslation } from "react-i18next";
import * as auth from "../../../../../app/modules/Auth/_redux/authRedux";

import {
  checkIshaAccess,
  checkIshaUSAccess,
} from "../../../../../app/pages/CalendarPage/constants/Calendar-constants";
import MyCartDropdownHelp from "../../extras/dropdowns/MyCartDropdownHelp";
const style = {
  position: "relative",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "fit-content",
  width: 750,
  bgcolor: "white",
  boxShadow: 24,
  p: 4,
  padding: "12px",
  borderRadius: "8px",
  outline: 0,
  overflowY: "scroll",
};

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: "28px", // Set your desired font size here
  },
}))(Tooltip);

const HeaderMenu = ({
  layoutProps,
  data,
  dataActions,
  selectedLanguage,
  countData,
  ...props
}) => {
  // const url = new URL(wi/ndow.location.href);
  const { t } = useTranslation();

  const CustomBackdrop = ({ style }) => {
    return (
      <Backdrop
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.2) !important",
          ...style,
        }}
      />
    );
  };

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);
  const envType = process.env.REACT_APP_ENV;
  const [state, setState] = useState({ right: false });
  const [mystate, setmyState] = useState();
  const textInput = useRef(null);

  const toggleDrawer = (anchor, open, id, a) => (event) => {
    setSelectedBookingId(id);
    // deFocus(a);

    // const data = await viewFolio(id);
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  // calculate the windows screen width and height
  const windowWidth = window.screen.width;

  const successAnimation = {
    loop: true,
    autoplay: true,
    animationData: successAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // modals in folios
  // add new service modal
  const [openServiceModal, setOpenServiceModal] = useState(false);
  const onOpenServiceModal = () => setOpenServiceModal(true);
  const onCloseServiceModal = () => setOpenServiceModal(false);

  // edit customer modal
  const [openEditCustomerModal, setOpenEditCustomerModal] = useState(false);
  const onOpenEditCustomerModal = () => setOpenEditCustomerModal(true);
  const onCloseEditCustomerModal = () => setOpenEditCustomerModal(false);

  const [values, setvalues] = useState();
  const [resultes, setResult] = useState();
  const [search, setSearch] = useState();
  const [dates, setDates] = useState([]);
  const myValue = (params) => {
    setSearch(params.get("mainSearch"));
    setResult(params.size);
  };

  const customQueryMovie = (query) => {
    if (!query) {
      return { match_all: {} };
    }
    return {
      bool: {
        must: [
          {
            multi_match: {
              query,
              type: "phrase_prefix",
              fields: [
                "guestName",
                "bookingId",
                "guestEmail",
                "guestPhone",
                "otaBookingId",
                "lastBookingIdDigits",
              ],
            },
          },
        ],

        filter: {
          term: {
            hotel_id: hotelId,
          },
        },
      },
    };
  };

  useEffect(() => {
    if (
      data?.accessControl?.ADMINRIGHTS === "ADMIN" ||
      data?.accessControl?.ADMINRIGHTS === "SUBADMIN"
    ) {
      const searchInput = document.getElementById("mysearch");
      searchInput.addEventListener("focus", inputFocus);
      searchInput.addEventListener("focusout", deFocus);
    } else if (
      data?.accessControl?.ADMINRIGHTS !== "ADMIN" &&
      data?.accessControl?.ADMINRIGHTS !== "SUBADMIN" &&
      Object.entries(accessControl).length > 0 &&
      JSON.parse(accessControl?.GlobalSearchBar).read === "YES"
    ) {
      const searchInput = document.getElementById("mysearch");
      searchInput.addEventListener("focus", inputFocus);
      searchInput.addEventListener("focusout", deFocus);
    }

    return () => {};
  }, []);

  const inputFocus = (e) => {
    setvalues(-200);
  };

  const deFocus = (id) => {
    if (id === 2) {
      setvalues(2);
    } else {
      setvalues(-1);
    }
  };

  const location = useLocation();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  };

  const url = new URL(window.location.href);
  const hotelId = url.searchParams.get("hotelId");

  // const folioDrawer = (anchor) => (
  //   <Box
  //     sx={{
  //       width:
  //         anchor === "top" || anchor === "bottom" ? "auto" : windowWidth - 100,
  //     }}
  //     role="presentation"
  //     // onClick={toggleDrawer(anchor, false)}
  //     // onKeyDown={toggleDrawer(anchor, false)}
  //   >
  //     <div
  //       style={{
  //         position: "relative",
  //         width: "100%",
  //         padding: "25px",
  //       }}
  //     >
  //       <div
  //         onClick={toggleDrawer("right", false)}
  //         style={{
  //           position: "relative",
  //           cursor: "pointer",
  //           marginRight: "25px",
  //           width: "100% !important",
  //           display: "flex",
  //           justifyContent: "flex-end",
  //           backgroundColor: "transparent",
  //         }}
  //       >
  //         <AiIcons.AiOutlineClose size="25" color="black" />
  //       </div>
  //       {selectedBookingId && (
  //         <Folios
  //           onOpenCustModal={onOpenEditCustomerModal}
  //           onOpenAddServiceModal={onOpenServiceModal}
  //           booking_id={selectedBookingId}
  //           // onCloseAdvFolio={toggleDrawer("right", false)}
  //         />
  //       )}
  //     </div>
  //   </Box>
  // );

  const [selectedBookingId, setSelectedBookingId] = useState(null);
  const [searchValue, setsearchValue] = useState("");
  const [usersData, setusersData] = useState([]);

  const getValues = async (val) => {
    setsearchValue(val);
    handleSpringDataRequest(
      `core/api/v1/reservation/fetch-users-elastic-search/?search=${val}`
    )
      .then((res) => setusersData(res))
      .catch((err) =>
        toast.error(
          `${t("Error in getting selfCheckins Please try again later")}.`
        )
      );
  };

  const [accessControl, setaccessControl] = useState({});
  useEffect(async () => {
    if (
      localStorage.getItem("email") !== null &&
      localStorage.getItem("email") !== undefined
    ) {
      handleSpringDataRequest(
        `core/api/v1/accesscontrol/addUserWizardRender?email=${localStorage.getItem(
          "email"
        )}`
      )
        .then((res) => {
          setaccessControl(res?.user_feature_map);
        })
        .catch((err) => console.error(err));
      // const data = await getUserWizardRender(
      //   localStorage.getItem("hotelId"),
      //   localStorage.getItem("email")
      // );
    }
  }, [localStorage.getItem("email")]);

  const getDate = (myDate) => {
    const newDate = moment(myDate, "DD-MM-YYYY").format("Do MMMM, YYYY");

    return newDate;
  };

  //-------------------Account Freeze---------------------

  // const [freezed, setFreezed] = useState("");
  // const newGroupid = localStorage.getItem("newGroupid");
  // const findingAccountStatus = () => {
  //   handleSpringDataRequest(
  //     `core/api/v1/account/get-single/?groupId=${newGroupid}&hotelId=${newGroupid}`
  //   )
  //     .then((data) => setFreezed(data?.account_details?.freezed))
  //     .catch((err) => console.log(err));
  // };
  // useEffect(() => {
  //   findingAccountStatus();
  // }, []);

  const [paymentLoader, setpaymentLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [confirmModal, setconfirmModal] = useState(false);
  const handleMenuModal = () => {
    setOpen(true);
  };

  const [openReview, setopenReview] = useState(false);
  const handleOpenReviewModal = () => {
    setopenReview(true);
  };

  const startTrial = () => {
    setpaymentLoader(true);
    const payload = {
      hotelId: hotelId,
      groupId: localStorage.getItem("mainGroupId"),
      description: `Guest messaging upgrade`,
      type: "ADDON",
      amount: 0,
      addon_feature_id: "27",
    };
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/feature-upgrade/single-feature-upgrade`,
      payload
    )
      .then(() => {
        setpaymentLoader(false);
        setOpen(false);
        setconfirmModal(true);
      })
      .catch((err) => {
        toast.error(err.response.data);
        setpaymentLoader(false);
      });
    // handleSpringDataRequest(
    //   `core/api/v1/feature-upgrade/add-feature-to-featureSet?featureId=${132}`
    // )
    //   .then(() => {
    //     setpaymentLoader(false);
    //     setOpen(false);
    //     setconfirmModal(true);
    //   })
    //   .catch((err) => {
    //     toast.error(err.response.data);
    //     setpaymentLoader(false);
    //   });
  };

  const startTrialForReview = () => {
    setpaymentLoader(true);
    const payload = {
      hotelId: hotelId,
      groupId: localStorage.getItem("mainGroupId"),
      description: `Review management upgrade`,
      type: "ADDON",
      amount: 0,
      addon_feature_id: "132",
    };
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/feature-upgrade/single-feature-upgrade`,
      payload
    )
      .then(() => {
        setpaymentLoader(false);
        setopenReview(false);
        setconfirmModal(true);
      })
      .catch((err) => {
        toast.error(err.response.data);
        setpaymentLoader(false);
      });
    // handleSpringDataRequest(
    //   `core/api/v1/feature-upgrade/add-feature-to-featureSet?featureId=${132}`
    // )
    //   .then(() => {
    //     setpaymentLoader(false);
    //     setOpen(false);
    //     setconfirmModal(true);
    //   })
    //   .catch((err) => {
    //     toast.error(err.response.data);
    //     setpaymentLoader(false);
    //   });
  };

  const [csmData, setCsmData] = useState(null);
  useEffect(async () => {
    const groupId = data?.accessControl?.hotel?.group_id;
    if (groupId && csmData == null) {
      await getCsmDetails(groupId).then((resp) => {
        if (String(resp?.status) === "200") {
          const data = resp?.data;
          setCsmData(data);
        }
      });
    }
  }, [data?.accessControl?.hotel?.group_id]);
  const [whiteLabelled, setWhiteLabelled] = useState("ENABLED");
  const userBrandingSatus = async () => {
    try {
      const data = await getAllImagesAndUrl();
      if (data?.status === null || data?.status === undefined) {
        setWhiteLabelled("DISABLED");
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    userBrandingSatus();
  }, []);

  const getImage = () => {
    return "https://storage.googleapis.com/prod-images-bucket/misc/AI-powered%20Reputation%20Management%20(10).png";
  };

  const getText = () => {
    if (data?.accessControl?.hotel?.hotel_currency === "INR") {
      return (
        <div>
          {`${t("Upgrade for INR")} `}
          <span style={{ textDecoration: "line-through" }}>999</span> 0 /{" "}
          {` ${t("month")}`}
        </div>
      );
    } else if (data?.accessControl?.hotel?.hotel_currency !== "INR") {
      return (
        <div>
          {`${t("Upgrade for")} `}
          <span style={{ textDecoration: "line-through" }}>$49</span> $0 /{" "}
          {` ${t("month")}`}
        </div>
      );
    }
  };

  const getMessageCout = () => {
    handleSpringDataRequest(`core/api/v1/message/get-unread-conversation-count`)
      .then((res) => {
        props.SetConversationCounts(res?.totalCount || 0);
      })
      .catch(() => {});
  };
  const getReviewCout = () => {
    handleSpringDataRequest(
      "core/api/v1/airbnb-review/get-listings-unread-count"
    )
      .then((res) => {
        props.SetReviewCounts(res?.totalCount || 0);
      })
      .catch(() => {});
  };
  useEffect(() => {
    getReviewCout();
    getMessageCout();
  }, []);
  return (
    <div
      id="kt_header_menu"
      className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
      {...layoutProps.headerMenuAttributes}
    >
      {/* <Modal
        open={freezed === "YES"}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Freeze />
      </Modal> */}

      {/*begin::Header Nav*/}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li
          className={`menu-item menu-item-rel ${getMenuItemActive(
            "/dashboard"
          )}`}
        >
          <CustomTooltip
            title={
              <Typography
                sx={{
                  fontSize: "16px",
                }}
              >
                {`${t("Dashboard")}`}
              </Typography>
            }
            arrow
          >
            <Link
              className="menu-link"
              to={{ pathname: "/dashboard", search: `?hotelId=${hotelId}` }}
            >
              <span className="menu-text">
                <MdIcons.MdOutlineDashboard size="22.5" color="#36454F" />
              </span>
              {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
            </Link>
          </CustomTooltip>
        </li>
        {Object.entries(accessControl).length > 0 &&
          JSON.parse(accessControl?.ReservationGrid).read === "YES" && (
            <li
              className={`menu-item menu-item-rel ${getMenuItemActive(
                "/calendar"
              )}`}
            >
              <CustomTooltip
                title={
                  <Typography
                    sx={{
                      fontSize: "16px",
                    }}
                  >
                    {`${t("Reservation calendar")}`}
                  </Typography>
                }
                arrow
              >
                <Link
                  className="menu-link"
                  to={{
                    pathname: "/calendar",
                    search: `?hotelId=${hotelId}`,
                  }}
                >
                  <span className="menu-text">
                    <FaIcons.FaCalendarAlt size="20" color="#36454F" />
                  </span>
                  {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
                </Link>
              </CustomTooltip>
            </li>
          )}
        {Object.entries(accessControl).length > 0 &&
          JSON.parse(accessControl?.InventoryCalendar).read === "YES" && (
            <li
              className={`menu-item menu-item-rel ${getMenuItemActive(
                "/inventory-calendar"
              )}`}
            >
              <CustomTooltip
                title={
                  <Typography
                    sx={{
                      fontSize: "16px",
                    }}
                  >
                    {`${t("Rates and inventory")}`}
                  </Typography>
                }
                id="mycart-panel-tooltip"
                arrow
              >
                <Link
                  className="menu-link"
                  to={{
                    pathname: "/inventory-calendar",
                    search: `?hotelId=${hotelId}`,
                  }}
                >
                  <span className="menu-text">
                    <MdIcons.MdOutlineInventory color="#36454F" size="20" />
                  </span>
                  {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
                </Link>
              </CustomTooltip>
            </li>
          )}

        {Object.entries(accessControl).length > 0 &&
          JSON.parse(accessControl?.HouseKeeping).read === "YES" && (
            <li
              className={`menu-item menu-item-rel ${getMenuItemActive(
                "/housekeeping"
              )}`}
            >
              <CustomTooltip
                title={
                  <Typography
                    sx={{
                      fontSize: "16px",
                    }}
                  >
                    {`${t("Housekeeping")}`}
                  </Typography>
                }
                arrow
              >
                <Link
                  className="menu-link"
                  to={{
                    pathname: "/housekeeping",
                    search: `?hotelId=${hotelId}`,
                  }}
                >
                  <img
                    src={toAbsoluteUrl("/media/quickPanel/housekeeping.png")}
                    className="memberImg"
                    style={{
                      width: "25px",
                      height: "25px",
                      filter:
                        "brightness(0) saturate(100%) invert(27%) sepia(18%) saturate(533%) hue-rotate(158deg) brightness(94%) contrast(86%)",
                    }}
                  />
                  {/* <span className="menu-text">
                    <CleaningServicesIcon
                      style={{ color: "#1BC5BD", fontSize: "20" }}
                    />
                  </span> */}
                  {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
                </Link>
              </CustomTooltip>
            </li>
          )}

        {localStorage.getItem("groupEmail") === "mhohotels@gmail.com" && (
          <li
            className={`menu-item menu-item-rel ${getMenuItemActive("/inbox")}`}
          >
            <CustomTooltip
              title={
                <Typography
                  sx={{
                    fontSize: "16px",
                  }}
                >
                  {`${t("Conversations")}`}
                </Typography>
              }
              arrow
            >
              <Link
                className="menu-link"
                to={{
                  pathname: "/inbox",
                  search: `?hotelId=${hotelId}`,
                }}
              >
                <span className="menu-text">
                  <Badge
                    badgeContent={countData?.conversationCounts}
                    sx={{
                      "& .MuiBadge-badge": {
                        backgroundColor: "#F64E60",
                        color: "white",
                      },
                    }}
                  >
                    <BsFillChatLeftDotsFill size="20" color="#36454F" />
                  </Badge>
                </span>
                {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
              </Link>
            </CustomTooltip>
          </li>
        )}
        {Object.entries(accessControl).length > 0 &&
        String(whiteLabelled) === "DISABLED" &&
        JSON.parse(accessControl?.UnifiedInbox).read === "YES" &&
        localStorage.getItem("groupEmail") !== "mhohotels@gmail.com" ? (
          <li
            className={`menu-item menu-item-rel ${getMenuItemActive("/inbox")}`}
          >
            <CustomTooltip
              title={
                <Typography
                  sx={{
                    fontSize: "16px",
                  }}
                >
                  {`${t("Conversations")}`}
                </Typography>
              }
              arrow
            >
              <Link
                className="menu-link"
                to={{
                  pathname: "/inbox",
                  search: `?hotelId=${hotelId}`,
                }}
              >
                <span className="menu-text">
                  <Badge
                    badgeContent={countData?.conversationCounts}
                    sx={{
                      "& .MuiBadge-badge": {
                        backgroundColor: "#F64E60",
                        color: "white",
                      },
                    }}
                  >
                    <BsFillChatLeftDotsFill size="20" color="#36454F" />
                  </Badge>
                </span>
                {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
              </Link>
            </CustomTooltip>
          </li>
        ) : Object.entries(accessControl).length > 0 &&
          data?.accessControl?.ADMINRIGHTS == "SUBUSER" ? (
          <></>
        ) : (
          localStorage.getItem("groupEmail") !== "mhohotels@gmail.com" &&
          String(whiteLabelled) === "DISABLED" && (
            <div
              style={{
                marginTop: "23px",
                marginLeft: "10px",
                marginRight: "10px",
              }}
              onClick={handleMenuModal}
            >
              <li
                className={`menu-item menu-item-rel ${getMenuItemActive(
                  "/inbox"
                )}`}
              >
                <span className="menu-text">
                  <BsFillChatLeftDotsFill size="20" color="#36454F" />
                </span>
                {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
              </li>
            </div>
          )
        )}

        {localStorage.getItem("groupEmail") === "mhohotels@gmail.com" && (
          <li
            className={`menu-item menu-item-rel ${getMenuItemActive(
              "/reputation-dashboard"
            )}`}
          >
            <CustomTooltip
              title={
                <Typography
                  sx={{
                    fontSize: "16px",
                  }}
                >
                  {`${t("Review management")}`}
                </Typography>
              }
              arrow
            >
              <Link
                className="menu-link"
                to={{
                  pathname: "/reputation-dashboard",
                  search: `?hotelId=${hotelId}`,
                }}
              >
                <span className="menu-text">
                  <Badge
                    badgeContent={countData?.reviewCounts}
                    sx={{
                      "& .MuiBadge-badge": {
                        backgroundColor: "#F64E60",
                        color: "white",
                      },
                    }}
                  >
                    <MdOutlineRateReview size="20" color="#36454F" />
                  </Badge>
                </span>
                {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
              </Link>
            </CustomTooltip>
          </li>
        )}

        {Object.entries(accessControl).length > 0 &&
        String(whiteLabelled) === "DISABLED" &&
        JSON.parse(accessControl?.ReputationManagement).read === "YES" &&
        localStorage.getItem("groupEmail") !== "mhohotels@gmail.com" ? (
          <li
            className={`menu-item menu-item-rel ${getMenuItemActive(
              "/reputation-dashboard"
            )}`}
          >
            <CustomTooltip
              title={
                <Typography
                  sx={{
                    fontSize: "16px",
                  }}
                >
                  {`${t("Review management")}`}
                </Typography>
              }
              arrow
            >
              <Link
                className="menu-link"
                to={{
                  pathname: "/reputation-dashboard",
                  search: `?hotelId=${hotelId}`,
                }}
              >
                <span className="menu-text">
                  <Badge
                    badgeContent={countData?.reviewCounts}
                    sx={{
                      "& .MuiBadge-badge": {
                        backgroundColor: "#F64E60",
                        color: "white",
                      },
                    }}
                  >
                    <MdOutlineRateReview size="20" color="#36454F" />
                  </Badge>
                </span>
                {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
              </Link>
            </CustomTooltip>
          </li>
        ) : Object.entries(accessControl).length > 0 &&
          data?.accessControl?.ADMINRIGHTS == "SUBUSER" ? (
          <></>
        ) : (
          localStorage.getItem("groupEmail") !== "mhohotels@gmail.com" &&
          String(whiteLabelled) === "DISABLED" && (
            <div
              style={{
                marginTop: "23px",
                marginLeft: "10px",
                marginRight: "10px",
              }}
              onClick={handleOpenReviewModal}
            >
              <li
                className={`menu-item menu-item-rel ${getMenuItemActive(
                  "/reputation-dashboard"
                )}`}
              >
                <span className="menu-text">
                  <MdOutlineRateReview size="20" color="#36454F" />
                </span>
                {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
              </li>
            </div>
          )
        )}

        {Object.entries(accessControl).length > 0 &&
          JSON.parse(accessControl?.GPMSRefundPage).read === "YES" &&
          checkIshaAccess(data?.emailId) && (
            <li
              className={`menu-item menu-item-rel ${getMenuItemActive(
                "/refund-page"
              )}`}
            >
              <CustomTooltip
                title={
                  <Typography
                    sx={{
                      fontSize: "16px",
                    }}
                  >
                    {`${t("Refund page")}`}
                  </Typography>
                }
                arrow
              >
                <Link
                  className="menu-link"
                  to={{
                    pathname: "/refund-page",
                    search: `?hotelId=${hotelId}`,
                  }}
                >
                  <span className="menu-text">
                    <FaIcons.FaMoneyCheckAlt size="20" color="#36454F" />
                    {/* <FaIcons.FaDonate size="20" color="1BC5BD" /> */}
                  </span>
                  {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
                </Link>
              </CustomTooltip>
            </li>
          )}
        {Object.entries(accessControl).length > 0 &&
          JSON.parse(accessControl?.EnquiryDashboard).read === "YES" &&
          (checkIshaAccess(data?.emailId) ||
            checkIshaUSAccess(data?.emailId)) && (
            <li
              className={`menu-item menu-item-rel ${getMenuItemActive(
                "/enquiry-page"
              )}`}
            >
              <CustomTooltip
                title={
                  <Typography
                    sx={{
                      fontSize: "16px",
                    }}
                  >
                    {`${t("Enquiry page")}`}
                  </Typography>
                }
                id="mycart-panel-tooltip"
                arrow
              >
                <NavLink
                  className="menu-link"
                  to={{
                    pathname: "/enquiry-page",
                    search: `?hotelId=${hotelId}`,
                  }}
                >
                  <span className="menu-text">
                    <BsIcons.BsQuestionDiamondFill color="#36454F" size="20" />
                  </span>
                  {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
                </NavLink>
              </CustomTooltip>
            </li>
          )}
        <li style={{ marginTop: "1rem", marginLeft: "1rem" }}>
          {(data?.accessControl?.ADMINRIGHTS === "ADMIN" ||
            data?.accessControl?.ADMINRIGHTS === "SUBADMIN") && (
            <TextField
              type={"search"}
              id="mysearch"
              value={searchValue}
              onChange={(e) => getValues(e.target.value)}
              label={`${t("Search for reservation")}`}
              placeholder={`${t("Search for reservation")}`}
              size="small"
              style={{ width: "25rem", height: "1rem" }}
            ></TextField>
          )}
          {data?.accessControl?.ADMINRIGHTS !== "ADMIN" &&
            data?.accessControl?.ADMINRIGHTS !== "SUBADMIN" &&
            Object.entries(accessControl).length > 0 &&
            JSON.parse(accessControl?.GlobalSearchBar).read !== "YES" && (
              <TextField
                type={"search"}
                id="mysearch"
                value={searchValue}
                onChange={(e) => getValues(e.target.value)}
                label={`${t("Search for reservation")}`}
                placeholder={`${t("Search for reservations")}`}
                size="small"
                style={{ width: "25rem", height: "1rem" }}
              ></TextField>
            )}
          {values === -200 && usersData?.length === 0 && (
            <div
              style={{
                position: "absolute",
                zIndex: "1",
                height: "22rem",
                marginTop: "1.3rem",
              }}
            >
              <Card
                style={{
                  width: "35rem",
                  height: "40%",
                  overflowY: "yes",
                }}
              >
                <Card.Body style={{ padding: "3%" }}>
                  <div className="d-flex align-items-center">
                    <BsSearch className="mb-0 mr-2" />
                    <p className="mb-0">
                      {" "}
                      {`${t("You can search reservations by")}:`}
                    </p>
                  </div>
                  <div>
                    <ul>
                      <li className="mt-2">
                        {" "}
                        {`${t("Guest Name, email, phone")}`}
                      </li>
                      <li className="mt-2">{`${t("Reservation number")}`}</li>
                    </ul>
                  </div>
                </Card.Body>
              </Card>
            </div>
          )}
          {usersData?.length > 0 && (
            <div
              onmouse
              style={{
                position: "absolute",
                zIndex: "1",
                overflowY: "scroll",
                height: "21rem",
                marginTop: "1.3rem",
              }}
            >
              {usersData?.length > 0 &&
                usersData?.map((data) => (
                  <Link
                    to={{
                      pathname: `/folio/${data.bookingId}`,
                      search: `?hotelId=${hotelId}`,
                    }}
                  >
                    {data.bookingId.includes(searchValue) ? (
                      <Card
                        style={{
                          width: "35rem",
                          height: "50%",
                          overflowY: "yes",
                        }}
                        key={data.bookingId}
                        onClick={() => {
                          setsearchValue("");
                          setusersData([]);
                        }}
                      >
                        <Card.Body style={{ padding: "3%" }}>
                          <div className="d-flex mb-0">
                            <h4 style={{ color: "black " }}>
                              <HighLighter
                                text={data.guestName}
                                highlight={searchValue}
                                highlightedItemClass="highlight"
                              />
                            </h4>
                            <p className="ml-3" style={{ color: "black" }}>
                              {data.bookingStatus === "ADMIN_CONFIRMED"
                                ? "Checked in"
                                : data.bookingStatus}
                            </p>
                          </div>
                          {data.bookingId !== data.otaBookingId && (
                            <p className="mb-0" style={{ color: "black" }}>
                              <HighLighter
                                text={data.bookingId}
                                highlight={searchValue}
                                highlightedItemClass="highlight"
                              />{" "}
                              -
                              <HighLighter
                                text={data.otaBookingId}
                                highlight={searchValue}
                                highlightedItemClass="highlight"
                              />
                            </p>
                          )}
                          {data.bookingId === data.otaBookingId && (
                            <p className="mb-0" style={{ color: "black" }}>
                              <HighLighter
                                text={data.bookingId}
                                highlight={searchValue}
                                highlightedItemClass="highlight"
                              />
                            </p>
                          )}

                          <div className="d-flex  ">
                            <div className="d-flex align-items-center mr-4">
                              {" "}
                              <AiOutlineMail className="p-0 m-0" />
                              <p
                                className="ml-2 p-0 mb-0"
                                style={{ color: "black" }}
                              >
                                <HighLighter
                                  text={data.guestEmail}
                                  highlight={searchValue}
                                  highlightedItemClass="highlight"
                                />
                              </p>
                            </div>
                            <div className="d-flex align-items-center">
                              <AiFillPhone className="p-0 m-0" />
                              <p
                                className="ml-2 p-0 mb-0"
                                style={{ color: "black" }}
                              >
                                <HighLighter
                                  text={data.guestPhone}
                                  highlight={searchValue}
                                  highlightedItemClass="highlight"
                                />
                              </p>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <AiOutlineCalendar className="p-0 m-0 mr-2" />
                            <p className="mb-0" style={{ color: "black" }}>
                              <HighLighter
                                text={getDate(data.checkin)}
                                highlight={searchValue}
                                highlightedItemClass="highlight"
                              />
                            </p>
                            <p
                              className="ml-3 mr-3 mb-0"
                              style={{ color: "black" }}
                            >
                              {" "}
                              -{" "}
                            </p>
                            <p className="mb-0" style={{ color: "black" }}>
                              <HighLighter
                                text={getDate(data.checkout)}
                                highlight={searchValue}
                                highlightedItemClass="highlight"
                              />
                            </p>
                          </div>
                        </Card.Body>
                      </Card>
                    ) : (
                      <Card
                        style={{
                          width: "35rem",
                          height: "50%",
                          overflowY: "yes",
                        }}
                        key={data.bookingId}
                        onClick={() => {
                          setsearchValue("");
                          setusersData([]);
                        }}
                      >
                        <Card.Body style={{ padding: "3%" }}>
                          <div className="d-flex mb-0">
                            <h4 style={{ color: "black " }}>
                              <HighLighter
                                text={data.guestName}
                                highlight={searchValue}
                                highlightedItemClass="highlight"
                              />
                            </h4>
                            <p className="ml-3" style={{ color: "black" }}>
                              {data.bookingStatus === "ADMIN_CONFIRMED"
                                ? "Checked in"
                                : data.bookingStatus}
                            </p>
                          </div>
                          {data.bookingId !== data.otaBookingId && (
                            <p className="mb-0" style={{ color: "black" }}>
                              <HighLighter
                                text={data.bookingId}
                                highlight={searchValue}
                                highlightedItemClass="highlight"
                              />{" "}
                              -
                              <HighLighter
                                text={data.otaBookingId}
                                highlight={searchValue}
                                highlightedItemClass="highlight"
                              />
                            </p>
                          )}
                          {data.bookingId === data.otaBookingId && (
                            <p className="mb-0" style={{ color: "black" }}>
                              <HighLighter
                                text={data.bookingId}
                                highlight={searchValue}
                                highlightedItemClass="highlight"
                              />
                            </p>
                          )}

                          <div className="d-flex  ">
                            <div className="d-flex align-items-center mr-4">
                              {" "}
                              <AiOutlineMail className="p-0 m-0" />
                              <p
                                className="ml-2 p-0 mb-0"
                                style={{ color: "black" }}
                              >
                                <HighLighter
                                  text={data.guestEmail}
                                  highlight={searchValue}
                                  highlightedItemClass="highlight"
                                />
                              </p>
                            </div>
                            <div className="d-flex align-items-center">
                              <AiFillPhone className="p-0 m-0" />
                              <p
                                className="ml-2 p-0 mb-0"
                                style={{ color: "black" }}
                              >
                                <HighLighter
                                  text={data.guestPhone}
                                  highlight={searchValue}
                                  highlightedItemClass="highlight"
                                />
                              </p>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <AiOutlineCalendar className="p-0 m-0 mr-2" />
                            <p className="mb-0" style={{ color: "black" }}>
                              <HighLighter
                                text={getDate(data.checkin)}
                                highlight={searchValue}
                                highlightedItemClass="highlight"
                              />
                            </p>
                            <p
                              className="ml-3 mr-3 mb-0"
                              style={{ color: "black" }}
                            >
                              {" "}
                              -{" "}
                            </p>
                            <p className="mb-0" style={{ color: "black" }}>
                              <HighLighter
                                text={getDate(data.checkout)}
                                highlight={searchValue}
                                highlightedItemClass="highlight"
                              />
                            </p>
                          </div>
                        </Card.Body>
                      </Card>
                    )}
                  </Link>
                ))}
            </div>
          )}
        </li>
        {/* <li>
          <MyCartDropdownHelp />
        </li> */}
      </ul>
      {/* <div>
        <Drawer
          anchor={"right"}
          open={state["right"]}
          // sx={{ width: "100%" }}
          onClose={toggleDrawer("right", false)}
          className="folioDrawer"
          PaperProps={{ sx: { width: "100%" } }}
        >
          {folioDrawer("right")}
        </Drawer>
      </div> */}

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="settleDuesModal"
          style={{
            boxShadow: "0px 0px 25px 10px #0000001a",
            height: "42rem",
            width: "82rem",
          }}
        >
          <div className="settleDuesWrapper">
            <div className="settleDuesHeader">
              <div className="settleDuesHeading">{`${t(
                "Upgrade for new features"
              )}`}</div>
              <div className="close" onClick={() => setOpen(false)}>
                X
              </div>
            </div>

            <div className="d-flex">
              <div className="amountWrapper">
                <h1 style={{ marginTop: "2rem" }}>
                  {`${t("Booking")}.${t("com and Airbnb guest messaging")}`}
                </h1>
                <h3 style={{ marginTop: "2rem", marginBottom: "2rem" }}>
                  {`${t("You can directly reply to booking")}.${t(
                    "com and airbnb guest messages from PMS"
                  )}.`}
                </h3>

                <div className="d-flex justify-content-center">
                  <span
                    class="badge badge-warning"
                    style={{
                      fontWeight: "bold",
                      height: "4rem",
                      width: "30rem",
                      marginTop: "3rem",
                      fontSize: "20px",
                      lineHeight: "28px",
                      marginRight: "18px",
                    }}
                  >
                    {`${t(`Limited time offer 100`)}%${t("discounted")}`}
                  </span>
                </div>
                <div
                  style={{
                    position: "absolute",
                    bottom: "2rem",
                    width: "100%",
                  }}
                >
                  <Button
                    variant="custom-button"
                    style={{
                      position: "absolute",
                      bottom: "4rem",
                      width: "46%",
                      fontWeight: "bold",
                      height: "4rem",
                      fontSize: "20px",
                    }}
                    onClick={() => {
                      if (data?.accessControl?.ADMINRIGHTS === "ADMIN") {
                        startTrial();
                      } else {
                        toast.warning(
                          `${t("Please contact admin to  enable this feature")}`
                        );
                      }
                    }}
                    disabled={paymentLoader}
                  >
                    {paymentLoader ? (
                      <CircularProgress
                        size={15}
                        color="inherit"
                        sx={{ ml: 1.5 }}
                      />
                    ) : data?.accessControl?.hotel?.hotel_currency === "INR" ? (
                      <div>
                        {`${t("Upgrade for INR")} `}
                        <span style={{ textDecoration: "line-through" }}>
                          999
                        </span>{" "}
                        0 / {` ${t("month")} `}
                      </div>
                    ) : (
                      <div>
                        {`${t("Upgrade for")} `}
                        <span style={{ textDecoration: "line-through" }}>
                          $49
                        </span>{" "}
                        $0 / {` ${t("month")} `}
                      </div>
                    )}
                  </Button>
                  {String(whiteLabelled) === "DISABLED" && (
                    <p
                      style={{
                        fontWeight: "bold",
                        textDecoration: "underline",
                        cursor: "pointer",
                        marginTop: "2rem",
                        color: "#1BC5BD",
                      }}
                      onClick={() =>
                        window.open(
                          Object.entries(csmData).length > 0
                            ? csmData.account_csm_calendar_link
                            : "https://calendly.com/bharath-gowda/30min",
                          "_blank"
                        )
                      }
                    >
                      {`${t("Talk to sales")} `}
                    </p>
                  )}
                </div>
              </div>

              <div>
                <img
                  style={{
                    height: "36rem",
                    width: "40rem",
                  }}
                  src={
                    "https://storage.googleapis.com/prod-images-bucket/misc/SF%20Mobile%20app%20New%20look.jpg"
                  }
                />
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openReview}
        onClose={() => setopenReview(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropComponent={CustomBackdrop}
      >
        <Fade in={openReview}>
          <Box
            className="settleDuesModal"
            style={{
              boxShadow: "0px 0px 25px 10px #0000001a",
              height: "53rem",
              width: "82rem",
            }}
          >
            <div className="settleDuesWrapper">
              <div className="settleDuesHeader">
                <div className="settleDuesHeading">
                  {`${t("Upgrade for new features")}`}
                </div>
                <div className="close" onClick={() => setopenReview(false)}>
                  X
                </div>
              </div>

              <div className="d-flex">
                <div
                  className="amountWrapper"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <h1 style={{ marginTop: "2rem" }}>
                    {`${t("AI powered review management")}`}
                  </h1>
                  <h3 style={{ marginTop: "2rem", marginBottom: "2rem" }}>
                    {`${t("Manage all your booking")}.${t(
                      "com and airbnb reviews in one place"
                    )}. Powered by FlexiGPT, you can automatically reply to reviews with guest sentiment analysis."
                      )}`}
                  </h3>

                  <>
                    <ul>
                      <li> {`${t("FlexiGPT AI-powered")}`}</li>
                      <li>{`${t("Booking and Airbnb reviews")}`}</li>
                    </ul>
                    <div className="d-flex justify-content-center">
                      <span
                        class="badge badge-warning"
                        style={{
                          fontWeight: "bold",
                          height: "4rem",
                          width: "30rem",
                          marginTop: "3rem",
                          fontSize: "20px",
                          lineHeight: "28px",
                        }}
                      >
                        {`${t("Limited time offer")}, 100% ${t("discounted")}"`}
                      </span>
                    </div>
                  </>

                  <div
                    style={{
                      position: "absolute",
                      bottom: "2rem",
                      width: "100%",
                    }}
                  >
                    <Button
                      variant="custom-button"
                      style={{
                        width: "46%",
                        fontWeight: "bold",
                        height: "4rem",
                        fontSize: "20px",
                      }}
                      onClick={() => {
                        if (data?.accessControl?.ADMINRIGHTS === "ADMIN") {
                          startTrialForReview();
                        } else {
                          toast.warning(
                            "Please contact admin to enable this feature"
                          );
                        }
                      }}
                      disabled={paymentLoader}
                    >
                      {paymentLoader ? (
                        <CircularProgress
                          size={15}
                          color="inherit"
                          sx={{ ml: 1.5 }}
                        />
                      ) : (
                        getText()
                      )}
                    </Button>
                    {String(whiteLabelled) === "DISABLED" && (
                      <p
                        style={{
                          fontWeight: "bold",
                          textDecoration: "underline",
                          cursor: "pointer",
                          marginTop: "2rem",
                          color: "#1BC5BD",
                        }}
                        onClick={() =>
                          window.open(
                            Object.entries(csmData).length > 0
                              ? csmData.account_csm_calendar_link
                              : "https://calendly.com/bharath-gowda/30min",
                            "_blank"
                          )
                        }
                      >
                        {`${t("Talk to sales")}`}
                      </p>
                    )}
                  </div>
                </div>
                <div>
                  <img
                    style={{
                      height: "36rem",
                      width: "40rem",
                    }}
                    src={getImage()}
                  />
                </div>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>

      <Modal
        open={confirmModal}
        onClose={() => setconfirmModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="settleDuesModal"
          style={{
            boxShadow: "0px 0px 25px 10px #0000001a",
            height: "25rem",
            width: "70rem",
          }}
        >
          <div className="settleDuesWrapper">
            <div className="settleDuesHeader">
              <div className="settleDuesHeading">
                {" "}
                {`${t("Upgrade for new features")}`}
              </div>
              <div className="close" onClick={() => setconfirmModal(false)}>
                X
              </div>
            </div>

            <div className="d-flex flex-column justify-content-center align-items-center w-100">
              <Lottie options={successAnimation} height={150} width={150} />
              <h1>
                {`${t(
                  "Successfully upgraded please logout and login to access this feature"
                )}`}
              </h1>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel, countData: state.auth };
}
export default connect(mapStateToProps, auth.actions)(HeaderMenu);
