import React, { useCallback, useEffect, useState } from "react";
import { handleSpringDataRequest } from "../../../api";
import { toast } from "material-react-toastify";
import moment from "moment";
import MockLoaderTable from "../../Folios/ViewManagePayments/MockLoaderTable";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Tooltips from "@mui/material/Tooltip";
import Slider from "react-slick";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Rating,
  Select,
  Typography,
} from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MuiSlider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import ViewHotels from "./ViewHotels";
import DashboardMockLoader from "../../../Utils/DashboardMockLoader";
import * as AiIcons from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { BiSupport } from "react-icons/bi";

function RMDashboard({
  currency,
  minRoomValue,
  hotelId,
  data,
  whiteLabelled,
  isGroup,
  leadTime,
  markettrend,
  roomPickupBySources,
  revenuePickupBySources,
  todayPickup,
  yesterDayPickup,
  todayOccupancy,
  yesterDayOccupancy,
  todayRevenue,
  yesterdayRevenue,
  revenueByRoomTypes,
  revenueBySources,
  rateShopperData,
  rateFinalData,
  ratesAvg,
  setrateFinalData,
  conversionfactor,
  sevenDaysRate,
  monthlyTrends,
  loader,
  moreThanOneProp,
  Accbalance,
  setshowNotif,
  showAutoPay,
  csmData,
  showNotif,
  hotelType,
  country,
  vacationRentals,
  openSupport,
  notifData,
}) {
  const history = useHistory();

  const [starCategory, setstarCategory] = useState("3");
  const [finalSevenDaysRate, setfinalSevenDaysRate] = useState([]);
  const [openBlock, setOpenBlock] = useState(false);
  const [todaysDate, settodaysDate] = useState(0);

  useEffect(() => {
    const newData = [
      { name: "Your base price", price: minRoomValue },
      ...rateShopperData,
    ];

    setrateFinalData(newData);
  }, [minRoomValue, rateShopperData]);

  const getpercent = (base, competitor) => {
    let basePrice = base === 0 ? 1 : base;
    let competitorPrice = competitor === 0 ? 1 : competitor;
    let percent = ((competitorPrice - basePrice) / basePrice) * 100;
    return Math.abs(parseFloat(percent).toFixed(2));
  };

  function formatNumber(num) {
    if (num >= 10000) {
      // Divide the number by 1000 and round it to two decimal places
      return (num / 1000).toFixed(1) + "k";
    } else {
      return num.toString(); // Convert number to string
    }
  }

  function formatNumber(num) {
    if (num > 9999) {
      // Divide the number by 1000 and round it to two decimal places
      return (num / 1000).toFixed(1) + "k";
    } else {
      return num.toString(); // Convert number to string
    }
  }

  const PrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: 1,
            left: "-6px",
          }}
        >
          <IconButton>
            <ChevronLeftIcon sx={{ fontSize: "2.25rem" }} />
          </IconButton>
        </Box>
      </div>
    );
  };

  const NextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: 1,
            right: "-6px",
          }}
        >
          <IconButton>
            <ChevronRightIcon
              sx={{ fontSize: "2.25rem", marginRight: "5px" }}
            />
          </IconButton>
        </Box>
      </div>
    );
  };

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow:
      finalSevenDaysRate?.length > 4 ? 4 : finalSevenDaysRate?.length, // Show 4 slides at a time
    slidesToScroll:
      finalSevenDaysRate?.length <= 4 ? 0 : finalSevenDaysRate?.length - 4, // Scroll 3 slides at a time
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  useEffect(() => {
    if (sevenDaysRate?.length > 0) {
      const finalData = sevenDaysRate.filter(
        (item) => item.star === starCategory
      );
      setfinalSevenDaysRate(finalData);
    }
  }, [starCategory, sevenDaysRate]);

  const today = moment();
  const tomorrow = moment().add(1, "days");

  const getPriceValue = (price) => {
    return parseFloat(price * conversionfactor).toFixed(2);
  };

  var newsettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4, // Show 4 slides at a time
    slidesToScroll: 4, // Scroll 3 slides at a time
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  const getWeatherurl = (text) => {
    if (text === "Mostly sunny") {
      return "https://prop-images.s3.us-east-2.amazonaws.com/misc/cloudy+(1).png";
    } else if (text === "Typically 12+ rainy days") {
      return "https://prop-images.s3.us-east-2.amazonaws.com/misc/raining.png";
    } else {
      return "https://prop-images.s3.us-east-2.amazonaws.com/misc/cloudy.png";
    }
  };

  const getbuzyurl = (text) => {
    if (text === "Not busy") {
      return "https://prop-images.s3.us-east-2.amazonaws.com/misc/1.png";
    } else if (text === "Somewhat busy") {
      return "https://prop-images.s3.us-east-2.amazonaws.com/misc/2.png";
    } else if (text === "Busy") {
      return "https://prop-images.s3.us-east-2.amazonaws.com/misc/3.png";
    } else {
      return "https://prop-images.s3.us-east-2.amazonaws.com/misc/4.png";
    }
  };

  const getprice = (text) => {
    if (text === "Least expensive") {
      return "https://prop-images.s3.us-east-2.amazonaws.com/misc/1pricers.png";
    } else if (text === "Moderate") {
      return "https://prop-images.s3.us-east-2.amazonaws.com/misc/2pricers.png";
    } else if (text === "Pricier") {
      return "https://prop-images.s3.us-east-2.amazonaws.com/misc/3pricers.png";
    } else {
      return "https://prop-images.s3.us-east-2.amazonaws.com/misc/4pricers.png";
    }
  };

  useEffect(() => {
    if (monthlyTrends?.length > 0) {
      const monthOrder = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      // Get the current month
      const currentDate = new Date();
      const currentMonth = monthOrder[currentDate.getMonth()];

      // Find the starting index of the current month
      const startIndex = monthOrder.indexOf(currentMonth);

      // Create a new custom order starting from the current month
      const customOrder = monthOrder
        .slice(startIndex)
        .concat(monthOrder.slice(0, startIndex));

      // Function to get the index of the month based on custom order
      const getMonthIndex = (month) => customOrder.indexOf(month);

      // Sort the data based on the custom month order
      monthlyTrends.sort(
        (a, b) => getMonthIndex(a.month) - getMonthIndex(b.month)
      );
    }
  }, [monthlyTrends]);

  const hotelCity = capitalizeFirstChar(data?.accessControl?.hotel?.hotel_city);

  function capitalizeFirstChar(str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  return loader === false ? (
    <div>
      {String(whiteLabelled) === "DISABLED" &&
        hotelType !== "ISHA" &&
        notifData.length > 0 &&
        notifData[0].status === "ACTIVE" &&
        (notifData[0].country === "all" ||
          country === notifData[0].country) && (
          <div className="w-100">
            <div
              className="informationCardWrapperNotif"
              style={{
                height: "16rem",
                marginTop: "2rem",
                paddingLeft: "2rem",
                cursor: "default",
                display: `${showNotif === false && "none"}`,
              }}
            >
              <div className="w-100">
                <div
                  className="d-flex"
                  style={{ justifyContent: "space-between" }}
                >
                  {" "}
                  <p
                    className="badge badge-danger mb-0"
                    style={{ marginTop: "1rem" }}
                  >
                    {notifData[0].tag}
                  </p>
                  <AiIcons.AiOutlineClose
                    size="20px"
                    color="black"
                    style={{
                      float: "right",
                      marginRight: "1rem",
                      marginTop: "1rem",
                    }}
                    onClick={() => setshowNotif(false)}
                  />
                </div>
              </div>
              <div
                className="d-flex w-100"
                style={{ justifyContent: "space-between" }}
              >
                <div className="w-75">
                  <div
                    style={{
                      color: "black",
                      fontWeight: "500",
                      fontSize: "18px",
                      fontWeight: "bold",
                      width: "100%",
                      textAlign: "left",
                      marginTop: "1rem",
                    }}
                  >
                    {notifData[0].header}
                  </div>
                  <div
                    style={{
                      color: "black",
                      fontWeight: "500",
                      fontSize: "18px",
                      marginTop: "1rem",
                      marginBottom: "0.5rem",
                      width: "100%",
                      textAlign: "left",
                    }}
                  >
                    {notifData[0].message}
                  </div>
                  <div className="w-100" style={{ marginTop: "1rem" }}>
                    <Button
                      style={{ float: "left" }}
                      variant="custom-button"
                      onClick={() =>
                        window.open(`${notifData[0].button_link}`, "_blank")
                      }
                    >
                      {notifData[0].button_name}
                    </Button>
                  </div>
                </div>
                {notifData[0].image !== "" && (
                  <div className="w-25">
                    <img
                      style={{
                        width: "50%",
                        marginRight: "4rem",
                        height: "90%",
                        borderRadius: "10px",
                      }}
                      src={notifData[0].image}
                      alt=""
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

      <div
        className="d-flex"
        style={{ justifyContent: "flex-start", marginTop: "2rem" }}
      >
        <div
          style={{
            height: "14rem",
            border: "1px solid #E4E4E4",
            borderRadius: "15px",
            marginRight: "2rem",
          }}
        >
          <div
            className="colorCode"
            style={{
              backgroundColor: "#96C7C1",
              height: "1.3rem",
              borderRadius: "15px 15px 0px 0px",
            }}
          />
          <div
            className="d-flex flex-column"
            style={{ paddingLeft: "5px", paddingRight: "5px" }}
          >
            <p
              style={{
                color: `${
                  todayOccupancy > yesterDayOccupancy ? "green" : "red"
                }`,
                marginTop: "auto",
                marginLeft: "10px",
                fontSize: "15px",
                marginLeft: "auto",
                fontWeight: "bold",
                marginBottom: "0px",
                height: "20px",
              }}
            >
              {yesterDayOccupancy === 0
                ? ""
                : todayOccupancy > yesterDayOccupancy
                ? `+${parseFloat(
                    ((todayOccupancy - yesterDayOccupancy) /
                      yesterDayOccupancy) *
                      100
                  ).toFixed(2)}%`
                : `${parseFloat(
                    ((todayOccupancy - yesterDayOccupancy) /
                      yesterDayOccupancy) *
                      100
                  ).toFixed(2)}%`}
            </p>
            <p
              style={{
                color: "gray",
                textAlign: "start",
                marginLeft: "auto",
              }}
            >
              {yesterDayOccupancy === 0 ? "" : `Since yesterday`}
            </p>
            <p
              style={{
                fontSize: "3rem",
                fontWeight: "bold",
                marginBottom: "0px",
                marginLeft: "1rem",
                color: "black",
                marginTop: `${yesterDayOccupancy === 0 ? "33px" : "1rem"}`,
              }}
            >
              {todayOccupancy}%
            </p>
            <h1
              style={{
                marginLeft: "1rem",
                color: "#808080",
                fontSize: "1.3rem",
                fontWeight: "bold",
              }}
            >
              Today's occupancy
            </h1>
          </div>
        </div>

        <div
          style={{
            height: "14rem",
            border: "1px solid #E4E4E4",
            borderRadius: "15px",
            marginRight: "2rem",
          }}
        >
          <div
            className="colorCode"
            style={{
              backgroundColor: "#CCF2CB",
              height: "1.3rem",
              borderRadius: "15px 15px 0px 0px",
            }}
          />
          <div
            className="d-flex flex-column"
            style={{ paddingLeft: "5px", paddingRight: "5px" }}
          >
            {" "}
            <p
              style={{
                color: `${todayRevenue > yesterdayRevenue ? "green" : "red"}`,
                marginTop: "auto",
                marginLeft: "10px",
                fontSize: "15px",
                marginLeft: "auto",
                fontWeight: "bold",
                marginBottom: "0px",
                height: "20px",
              }}
            >
              {yesterdayRevenue === 0
                ? ""
                : todayRevenue > yesterdayRevenue
                ? `+${parseFloat(
                    ((todayRevenue - yesterdayRevenue) / yesterdayRevenue) * 100
                  ).toFixed(2)}%`
                : `${parseFloat(
                    ((todayRevenue - yesterdayRevenue) / yesterdayRevenue) * 100
                  ).toFixed(2)}%`}
            </p>
            <p
              style={{
                color: "gray",
                textAlign: "start",
                marginLeft: "auto",
              }}
            >
              {yesterdayRevenue === 0 ? "" : `Since yesterday`}
            </p>
            <p
              style={{
                fontSize: "3rem",
                fontWeight: "bold",
                marginBottom: "0px",
                marginLeft: "1rem",
                marginRight: "1rem",
                color: "black",
                marginTop: `${yesterdayRevenue === 0 ? "33px" : "1rem"}`,
              }}
            >
              {currency}
              {formatNumber(todayRevenue)}
            </p>
            <h1
              style={{
                marginLeft: "1rem",
                color: "#808080",
                fontSize: "1.3rem",
                fontWeight: "bold",
              }}
            >
              Today's revenue
            </h1>
          </div>
        </div>

        <div
          style={{
            height: "14rem",
            border: "1px solid #E4E4E4",
            borderRadius: "15px",
            marginRight: "2rem",
          }}
        >
          <div
            className="colorCode"
            style={{
              backgroundColor: "#F7D59C",
              height: "1.3rem",
              borderRadius: "15px 15px 0px 0px",
            }}
          />
          <div
            className="d-flex flex-column"
            style={{ paddingLeft: "5px", paddingRight: "5px" }}
          >
            <p
              style={{
                color: `${todayPickup > yesterDayPickup ? "green" : "red"}`,
                marginTop: "auto",
                marginLeft: "10px",
                fontSize: "15px",
                marginLeft: "auto",
                fontWeight: "bold",

                marginBottom: "0px",
                height: "20px",
              }}
            >
              {yesterDayPickup === 0
                ? ""
                : todayPickup > yesterDayPickup
                ? `+${(
                    parseFloat(
                      (todayPickup - yesterDayPickup) / yesterDayPickup
                    ) * 100
                  ).toFixed(2)}%`
                : `${parseFloat(
                    ((todayPickup - yesterDayPickup) / yesterDayPickup) * 100
                  ).toFixed(2)}%`}
            </p>
            <p
              style={{
                color: "gray",
                textAlign: "start",
                marginLeft: "auto",
              }}
            >
              {yesterDayPickup === 0 ? "" : `Since yesterday`}
            </p>{" "}
            <p
              style={{
                fontSize: "3rem",
                fontWeight: "bold",
                marginBottom: "0px",
                marginLeft: "1rem",
                marginRight: "1rem",
                color: "black",
                marginTop: `${yesterDayPickup === 0 ? "33px" : "1rem"}`,
              }}
            >
              {todayPickup} {todayPickup < 2 ? "room" : "rooms"}
            </p>
            <h1
              style={{
                marginLeft: "1rem",
                color: "#808080",
                fontSize: "1.3rem",
                fontWeight: "bold",
              }}
            >
              Today's pickup
            </h1>
          </div>
        </div>
        <div
          style={{
            height: "14rem",
            border: "1px solid #E4E4E4",
            borderRadius: "15px",
            marginRight: "2rem",
          }}
        >
          <div
            className="colorCode"
            style={{
              backgroundColor: "#6C79A1",
              height: "1.3rem",
              borderRadius: "15px 15px 0px 0px",
            }}
          />
          <div
            className="d-flex flex-column"
            style={{ paddingLeft: "5px", paddingRight: "5px" }}
          >
            <p
              style={{
                marginTop: "auto",
                marginLeft: "10px",
                marginLeft: "auto",
                color: "gray",
              }}
            >
              {"for last 30 days"}
            </p>
            <p
              style={{
                fontSize: "3rem",
                fontWeight: "bold",
                marginBottom: "0px",
                marginLeft: "1rem",
                marginRight: "1rem",
                color: "black",
                marginTop: "33px",
              }}
            >
              {leadTime} days
            </p>
            <h1
              style={{
                marginLeft: "1rem",
                color: "#808080",
                fontSize: "1.3rem",
                fontWeight: "bold",
              }}
            >
              Pre-booking window
            </h1>
          </div>
        </div>
      </div>

      {finalSevenDaysRate?.length > 0 && (
        <div
          className="d-flex w-100"
          style={{ justifyContent: "space-between", marginTop: "5rem" }}
        >
          <div className="w-100 ">
            <div
              className="d-flex"
              style={{ justifyContent: "space-between", marginBottom: "1rem" }}
            >
              <div className="d-flex flex-column">
                {" "}
                <h1
                  style={{
                    fontWeight: "bold",
                    marginRight: "1.3rem",
                    fontSize: "2rem",
                    color: "black",
                    marginBottom: "0px",
                  }}
                >
                  {`${hotelCity} daily demand trends`}
                </h1>
                {/* <h4
                  style={{
                    marginBottom: "1rem",
                    color: "#808080",
                    marginTop: "7px",
                    fontSize: "1.1rem",
                  }}
                >
                  Based on our analysis of your daily demand trends, we have
                  gathered insights into how much guests usually pay.
                </h4> */}
              </div>
              <div
                className="d-flex flex-column"
                style={{
                  marginBottom: "0px",
                  marginTop: "auto",
                  marginRight: "2rem",
                }}
              >
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "gray",
                    marginBottom: "0px",
                  }}
                >
                  Filter by rating
                </p>
                <Rating
                  name="simple-controlled"
                  value={starCategory}
                  onChange={(event, newValue) => {
                    if (newValue >= 2) {
                      setstarCategory(`${newValue}`);
                    }
                  }}
                />
              </div>
            </div>

            <Slider {...settings}>
              {finalSevenDaysRate?.length > 0 &&
                finalSevenDaysRate.map((item, index) => {
                  const itemDate = moment(item.date, "MMM DD YYYY");
                  let displayDate;

                  if (itemDate.isSame(today, "day")) {
                    displayDate = "Today";
                  } else if (itemDate.isSame(tomorrow, "day")) {
                    displayDate = "Tomorrow";
                  } else {
                    displayDate = itemDate.format("MMM DD YYYY");
                  }

                  return (
                    <div
                      key={index}
                      style={{
                        height: "15rem",
                        border: "1px solid #E4E4E4",
                        borderRadius: "15px",
                        padding: "5px",
                        marginRight: "2rem",
                      }}
                    >
                      <div
                        style={{
                          height: "15rem",
                          border: "1px solid #E4E4E4",
                          borderRadius: "15px",
                          padding: "5px",
                          marginRight: "2rem",
                        }}
                      >
                        <div className="d-flex flex-column">
                          <p
                            style={{
                              marginLeft: "auto",
                              marginRight: "auto",
                              color: "#808080",
                              fontWeight: "bold",
                              fontSize: "15px",
                            }}
                          >
                            {displayDate}
                          </p>
                          <Divider style={{ marginBottom: "2rem" }} />

                          <div className="w-100">
                            {item.demand.avg_value === 0 ||
                            item.demand.avg_value === "0" ? (
                              <h3
                                style={{
                                  color: "gray",
                                  marginTop: "1rem",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                No data found
                              </h3>
                            ) : (
                              <>
                                <div
                                  className="w-100"
                                  style={{ marginTop: "1rem" }}
                                >
                                  <ScaleContainer>
                                    <Section
                                      color="#F28B82"
                                      style={{
                                        borderRadius: "50px",
                                      }}
                                    />
                                    <Section
                                      color="#FDD663"
                                      style={{
                                        borderRadius: "50px",
                                        marginLeft: "3px",
                                        marginRight: "3px",
                                      }}
                                    />
                                    <Section
                                      color="#81C995"
                                      style={{
                                        borderRadius: "50px",
                                      }}
                                    />
                                    <Marker
                                      value={
                                        item.demand.rate_range.includes(
                                          "TypicalTypical"
                                        )
                                          ? 52
                                          : item.demand.rate_range.includes(
                                              "HighHigher"
                                            )
                                          ? 75
                                          : 30
                                      }
                                    />
                                    <Tooltip
                                      value={
                                        item.demand.rate_range.includes(
                                          "TypicalTypical"
                                        )
                                          ? 52
                                          : item.demand.rate_range.includes(
                                              "HighHigher"
                                            )
                                          ? 75
                                          : 30
                                      }
                                      color={
                                        item.demand.rate_range.includes(
                                          "TypicalTypical"
                                        )
                                          ? "#FDD663"
                                          : item.demand.rate_range.includes(
                                              "HighHigher"
                                            )
                                          ? "#81C995"
                                          : "#F28B82"
                                      }
                                    >
                                      {item.demand.rate_range.includes(
                                        "TypicalTypical"
                                      )
                                        ? `${currency} ${getPriceValue(
                                            item.demand.avg_value
                                          )} is typical`
                                        : item.demand.rate_range.includes(
                                            "HighHigher"
                                          )
                                        ? `${currency} ${getPriceValue(
                                            item.demand.avg_value
                                          )} is high`
                                        : `${currency} ${getPriceValue(
                                            item.demand.avg_value
                                          )} is low`}
                                    </Tooltip>
                                  </ScaleContainer>
                                </div>

                                <p
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    color: "black",
                                    marginTop: "15px",
                                  }}
                                >
                                  {`Usually ${currency}${getPriceValue(
                                    item.demand.lower_value
                                  )} - ${currency}${getPriceValue(
                                    item.demand.upper_value
                                  )} per night`}
                                </p>

                                <p
                                  style={{
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                    color: "#1BC5BD",
                                    fontSize: "15px",
                                    marginBottom: "0px",
                                    fontWeight: "bold",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                  onClick={() => {
                                    setOpenBlock(true);
                                    settodaysDate(item.epoch);
                                  }}
                                >
                                  {"View hotels"}
                                </p>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </Slider>
          </div>
        </div>
      )}

      {monthlyTrends?.length > 0 && (
        <div
          className="d-flex w-100"
          style={{ justifyContent: "space-between", marginTop: "5rem" }}
        >
          <div className="w-100 ">
            <div
              className="d-flex"
              style={{ justifyContent: "space-between", marginBottom: "1rem" }}
            >
              <div
                className="d-flex flex-column"
                style={{ marginBottom: "1.2rem" }}
              >
                <h1
                  style={{
                    fontWeight: "bold",
                    marginRight: "1.3rem",
                    fontSize: "2rem",
                    color: "black",
                    marginBottom: "0px",
                  }}
                >
                  {`${hotelCity} monthly demand trends`}
                </h1>
                {/* <h4
                  style={{
                    marginBottom: "1rem",
                    color: "#808080",
                    marginTop: "7px",
                    fontSize: "1.1rem",
                  }}
                >
                  {monthlyTrends[0].insights.overview}
                </h4> */}
              </div>
            </div>

            <Slider {...newsettings}>
              {monthlyTrends?.length > 0 &&
                monthlyTrends.map((item, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        minHeight: "19rem",
                        border: "1px solid #E4E4E4",
                        borderRadius: "15px",
                        padding: "5px",
                        marginRight: "2rem",
                      }}
                    >
                      <div
                        style={{
                          minHeight: "19rem",
                          border: "1px solid #E4E4E4",
                          borderRadius: "15px",
                          padding: "5px",
                          marginRight: "2rem",
                        }}
                      >
                        <div className="d-flex flex-column">
                          <p
                            style={{
                              marginLeft: "auto",
                              marginRight: "auto",
                              color: "#808080",
                              fontWeight: "bold",
                              fontSize: "15px",
                            }}
                          >
                            {item.month}
                          </p>
                          <Divider style={{ marginBottom: "1rem" }} />

                          <div className="w-100">
                            {item.insights.lowTemp !== "Not Available" && (
                              <div
                                className="d-flex"
                                style={{ alignItems: "center" }}
                              >
                                <div style={{ width: "22%" }}>
                                  <img
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      marginRight: "1rem",
                                    }}
                                    src={getWeatherurl(
                                      item.insights.weatherText
                                    )}
                                  />
                                </div>

                                <div className="d-flex flex-column">
                                  <p
                                    className="mb-0"
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "14px",
                                    }}
                                  >{`${item.insights.lowTemp} - ${item.insights.highTemp}`}</p>
                                  <p className="mb-0">
                                    {item.insights.weatherText}
                                  </p>
                                </div>
                              </div>
                            )}
                            <div
                              className="d-flex"
                              style={{
                                alignItems: "center",
                                marginTop: "6px",
                                marginBottom: "6px",
                              }}
                            >
                              <div style={{ width: "22%" }}>
                                <img
                                  style={{
                                    width: "45px",
                                    height: "26px",
                                    marginRight: "1rem",
                                  }}
                                  src={getbuzyurl(item.insights.visitInsights)}
                                />
                              </div>

                              <div className="d-flex flex-column">
                                <p
                                  className="mb-0"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                  }}
                                >
                                  {item.insights.visitInsights}
                                </p>
                                <p className="mb-0">
                                  {item.insights.visitInsightsText}
                                </p>
                              </div>
                            </div>

                            {item?.insights?.lowPrice !== "Not Available" && (
                              <div
                                className="d-flex"
                                style={{ alignItems: "center" }}
                              >
                                <div style={{ width: "22%" }}>
                                  {" "}
                                  <img
                                    style={{
                                      width: "70px",
                                      height: "80px",
                                      marginLeft: "-10px",
                                    }}
                                    src={getprice(item.insights.rateInsights)}
                                  />
                                </div>

                                <div className="d-flex flex-column">
                                  <p
                                    className="mb-0"
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {item.insights.rateInsights}
                                  </p>
                                  <p className="mb-0">
                                    {`Hotels usually cost ${currency}${getPriceValue(
                                      item?.insights?.lowPrice
                                    )} - ${currency}${getPriceValue(
                                      item?.insights?.highPrice
                                    )} per night`}
                                  </p>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </Slider>
          </div>
        </div>
      )}

      {/* {markettrend.length > 0 && (
        <div
          className="d-flex w-100"
          style={{ justifyContent: "space-between", marginTop: "5rem" }}
        >
          <div className="w-100 ">
            <div className="d-flex align-items-center mb-3">
              <h1
                style={{
                  fontWeight: "bold",
                  marginRight: "1.3rem",
                  marginBottom: "0px",
                  fontSize: "2rem",
                  color: "black",
                }}
              >
                Demand trends
              </h1>
              <h4
                style={{
                  marginBottom: "0px",
                  marginTop: "auto",
                  color: "#808080",
                }}
              >
                We have analyzed your city and we have gathered some insights
                for you.
              </h4>
            </div>

            <div>
              <div className="d-flex w-100" style={{ marginTop: "2rem" }}>
                {markettrend.map((item) => {
                  return (
                    <div
                      style={{
                        width: `${
                          gettext(item.occupancy) === "Moderate demand"
                            ? "26rem"
                            : "20rem"
                        }`,
                        height: "13rem",
                        border: "1px solid #E4E4E4",
                        borderRadius: "15px",
                        padding: "5px",
                        marginRight: "2rem",
                        backgroundColor: `${getColor(item.occupancy)}`,
                      }}
                    >
                      <div className="d-flex flex-column">
                        <p
                          style={{
                            marginTop: "auto",
                            marginLeft: "10px",
                            fontSize: "20px",
                            marginLeft: "auto",
                            color: "black",
                            marginRight: "1rem",
                          }}
                        >
                          {getFormattedDate(item.name)}
                        </p>
                        <p
                          style={{
                            fontSize: "2rem",
                            fontWeight: "bold",
                            marginBottom: "0px",
                            marginLeft: "1rem",
                            marginTop: "2rem",
                            color: "black",
                          }}
                        >
                          {gettext(item.occupancy)}
                        </p>
                        <p
                          style={{
                            marginLeft: "1rem",
                            fontSize: "1.5rem",
                            color: "black",
                          }}
                        >
                          {gettextData(item.occupancy)}
                        </p>
                      </div>
                    </div>

                    // <div className="d-flex">
                    //   <h3
                    //     style={{
                    //       marginRight: "5rem",
                    //       width: "10rem",
                    //       fontWeight: "bold",
                    //     }}
                    //   >
                    //     {getFormattedDate(item.name)}
                    //   </h3>
                    //   <h3>{gettext(item.occupancy)}</h3>
                    // </div>
                  );
                })}
              </div>

              {String(whiteLabelled) === "DISABLED" &&
                localStorage.getItem("groupEmail") !== "df@stayflexi.com" &&
                isGroup !== "OK" && (
                  <h4 style={{ marginTop: "2rem" }}>
                    Our dedicated revenue managers can help your increase the
                    revenue, check out
                    <span
                      style={{ color: "#1DC5BD", cursor: "pointer" }}
                      onClick={() =>
                        window.open(
                          "https://business.stayflexi.com/campaign/stayflexi-empower-revenue-management-for-hotels",
                          "_blank"
                        )
                      }
                    >
                      {" "}
                      Stayflexi Empower
                    </span>
                  </h4>
                )}
            </div>
          </div>
        </div>
      )} */}

      {JSON.parse(data?.accessControl?.user_feature_map?.RMRateShopper).read ===
        "YES" && (
        <div
          className="d-flex w-100"
          style={{ justifyContent: "space-between", marginTop: "5rem" }}
        >
          <div className="w-100 ">
            <div className="d-flex flex-column">
              <h1
                style={{
                  fontWeight: "bold",
                  marginRight: "1.3rem",
                  marginBottom: "0px",
                  fontSize: "2rem",
                  color: "black",
                }}
              >
                Competitors intelligence
              </h1>
              <h4
                style={{
                  marginBottom: "0px",
                  color: "#808080",
                  fontSize: "1.1rem",
                }}
              >
                Your prices for today compared to your competitors. Setup or
                change your competitors{" "}
                <Link
                  to={{
                    pathname: `/revenue-dashboard/`,
                    search: `&hotelId=${hotelId}&key=8`,
                  }}
                  target="_blank"
                >
                  here.
                </Link>{" "}
              </h4>
            </div>

            {rateFinalData.length > 1 ? (
              <div>
                {" "}
                <div
                  className="d-flex flex-column w-100"
                  style={{ marginTop: "2rem" }}
                >
                  {rateFinalData.map((item, index) => {
                    // Truncate name to 15 characters and add '...' if it exceeds
                    const truncatedName =
                      item.name === "Your base price"
                        ? capitalizeFirstChar(item.name)
                        : item.name.length > 55
                        ? capitalizeFirstChar(item.name.substring(0, 55) + "..")
                        : capitalizeFirstChar(item.name);

                    return (
                      <div className="d-flex flex-column">
                        <div
                          className="d-flex"
                          style={{ marginBottom: "1rem" }}
                        >
                          <div className="w-50">
                            {index === 0 && (
                              <h3
                                style={{
                                  marginRight: "2rem",
                                  marginBottom: "0px",
                                  color: "gray",
                                }}
                              >
                                {truncatedName}
                              </h3>
                            )}
                            {index !== 0 && (
                              <Tooltips
                                key={index}
                                title={item.name}
                                placement="top"
                              >
                                <h3
                                  style={{
                                    marginRight: "2rem",
                                    marginBottom: "0px",
                                    color: "gray",
                                  }}
                                >
                                  {truncatedName}
                                </h3>
                              </Tooltips>
                            )}
                          </div>
                          <div className="w-25 d-flex">
                            <h3
                              style={{
                                fontWeight: "bold",
                                marginBottom: "0px",
                                color: "black",
                              }}
                            >
                              {item.price === 0
                                ? "Sold out"
                                : `${currency}${formatNumber(item.price)}`}
                            </h3>
                            {index !== 0 && item.price !== 0 && (
                              <p
                                style={{
                                  color: `${
                                    item.price > rateFinalData[0].price
                                      ? "green"
                                      : "red"
                                  }`,
                                  marginBottom: "0px",
                                  marginLeft: "5px",
                                  marginTop: "auto",
                                  fontWeight: "bold",
                                }}
                              >
                                {`${
                                  item.price > rateFinalData[0].price
                                    ? "+"
                                    : "-"
                                }${getpercent(
                                  rateFinalData[0].price,
                                  item.price
                                )}%`}
                              </p>
                            )}
                          </div>
                        </div>
                        {index === 0 && (
                          <Divider
                            style={{ marginBottom: "1rem", width: "75%" }}
                          />
                        )}
                      </div>
                      // <div
                      //   style={{
                      //     border: "1px solid #E4E4E4",
                      //     borderRadius: "15px",
                      //     padding: "5px",
                      //     marginRight: "2rem",
                      //   }}
                      // >
                      //   <div
                      //     className="d-flex flex-column"
                      //     style={{ marginLeft: "5px", marginRight: "5px" }}
                      //   >
                      //     {index !== 0 && (
                      //       <p
                      //         style={{
                      //           color: `${
                      //             item.price > rateFinalData[0].price
                      //               ? "green"
                      //               : "red"
                      //           }`,
                      //           marginTop: "auto",
                      //           marginLeft: "10px",
                      //           fontSize: "15px",
                      //           marginLeft: "auto",
                      //           fontWeight: "bold",
                      //           marginRight: "0.5rem",
                      //         }}
                      //       >
                      //         {`${
                      //           item.price > rateFinalData[0].price ? "+" : "-"
                      //         }${getpercent(
                      //           rateFinalData[0].price,
                      //           item.price
                      //         )}%`}
                      //       </p>
                      //     )}
                      //     <Tooltips
                      //       key={index}
                      //       title={item.name}
                      //       placement="top"
                      //     >
                      //       <p
                      //         style={{
                      //           fontSize: "1.5rem",
                      //           fontWeight: `${index === 0 && "bold"}`,
                      //           marginBottom: "0px",
                      //           marginLeft: "auto",
                      //           marginRight: "auto",
                      //           marginTop: `${index === 0 ? "48px" : "1rem"}`,
                      //           color: "black",
                      //           cursor: "pointer",
                      //         }}
                      //       >
                      //         {truncatedName}
                      //       </p>
                      //     </Tooltips>
                      //     <h1
                      //       style={{
                      //         marginLeft: "auto",
                      //         marginRight: "auto",
                      //         fontSize: "1.5rem",
                      //         color: "black",
                      //         marginTop: "1rem",
                      //         fontSize: "3rem",
                      //         fontWeight: "bold",
                      //       }}
                      //     >
                      //       {currency}
                      //       {formatNumber(item.price)}
                      //     </h1>
                      //   </div>
                      // </div>
                    );
                  })}
                </div>
                {/* <div className="w-100">
                  <div
                    className="d-flex"
                    style={{ marginBottom: "1rem", marginTop: "2rem" }}
                  >
                    <h2
                      style={{
                        marginRight: "2rem",
                        marginBottom: "0px",
                        fontWeight: "600",
                        color: "black",
                      }}
                    >
                      {"Your competitors average"}
                    </h2>
                    <h2
                      style={{
                        fontWeight: "bold",
                        marginBottom: "0px",
                        color: "black",
                      }}
                    >
                      {currency}
                      {ratesAvg}
                    </h2>
                    <p
                      style={{
                        color: `${
                          ratesAvg >= rateFinalData[0].price ? "green" : "red"
                        }`,
                        marginBottom: "0px",
                        marginLeft: "5px",
                        marginTop: "auto",
                        fontWeight: "bold",
                      }}
                    >
                      {`${
                        ratesAvg >= rateFinalData[0].price ? "+" : "-"
                      }${getpercent(rateFinalData[0].price, ratesAvg)}%`}
                    </p>
                  </div>
                </div> */}
              </div>
            ) : (
              <h4 style={{ marginTop: "5rem" }}>
                No competitors rates found please configure competitors by{" "}
                <Link
                  to={{
                    pathname: `/revenue-dashboard/`,
                    search: `&hotelId=${hotelId}&key=8`,
                  }}
                  target="_blank"
                >
                  clicking here.
                </Link>{" "}
              </h4>
            )}
          </div>
        </div>
      )}

      {/* sub graph */}

      <div
        className="d-flex w-100"
        style={{ justifyContent: "space-between", marginTop: "5rem" }}
      >
        <div className="w-50">
          {" "}
          <h1
            style={{
              fontWeight: "bold",
              fontSize: "2rem",
              color: "black",
              marginBottom: "0px",
            }}
          >
            Rooms pickup by source
          </h1>
          {/* <p style={{ fontWeight: "bold", fontSize: "3rem" }}>
            Rooms pickup by source
          </p> */}
          <h4
            style={{
              color: "#808080",
              fontSize: "1.1rem",
              marginBottom: "2rem",
            }}
          >
            Data is for past 7 days
          </h4>
          {roomPickupBySources.length > 0 ? (
            <div className="d-flex w-100">
              <div style={{ width: "80%" }}>
                <table className="table">
                  <thead>
                    <tr>
                      <th style={{ fontWeight: "bold" }}>Source</th>
                      <th style={{ fontWeight: "bold" }}>Room pickup</th>
                    </tr>
                  </thead>
                  <tbody>
                    {roomPickupBySources.map((entry, index) => (
                      <tr key={index}>
                        <td
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {entry.name}
                        </td>
                        <td
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {entry.roomsSold}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            // <BarChart
            //   width={500}
            //   height={300}
            //   data={roomPickupBySources}
            //   style={{ marginLeft: "-5rem" }}
            // >
            //   <XAxis
            //     dataKey="name"
            //     tick={{
            //       fontSize: 12,
            //       fill: "black",
            //       angle: -15,
            //       textAnchor: "end",
            //     }}
            //     tickFormatter={getXaxis}
            //   />
            //   <YAxis tick={<CustomYAxisTick />} display="none" />
            //   <Tooltip content={<CustomTooltipContentroomPickupBySources />} />
            //   <Bar dataKey="roomsSold" fill="#1DC5BD">
            //     <LabelList
            //       dataKey="roomsSold"
            //       position="top"
            //       content={(props) => (
            //         <CustomLabelWithoutCurrency
            //           {...props}
            //           width={props.width}
            //         />
            //       )}
            //     />
            //   </Bar>
            // </BarChart>
            <div
              className="w-100"
              style={{ marginTop: "3rem", marginLeft: "10rem" }}
            >
              {" "}
              <h4 style={{ color: "#808080" }}>No data found</h4>
            </div>
          )}
        </div>
        <div className="w-50">
          {" "}
          <h1
            style={{
              fontWeight: "bold",
              fontSize: "2rem",
              color: "black",
              marginBottom: "0px",
            }}
          >
            Revenue pickup by source
          </h1>
          <h4
            style={{
              color: "#808080",
              fontSize: "1.1rem",
              marginBottom: "2rem",
            }}
          >{`Data is for past 7 days `}</h4>
          {revenuePickupBySources.length > 0 ? (
            <div className="d-flex w-100">
              <div style={{ width: "80%" }}>
                <table className="table">
                  <thead>
                    <tr>
                      <th style={{ fontWeight: "bold" }}>Source</th>
                      <th
                        style={{ fontWeight: "bold" }}
                      >{`Revenue ( ${currency} )`}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {revenuePickupBySources.map((entry, index) => (
                      <tr key={index}>
                        <td
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {entry.name}
                        </td>
                        <td
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {entry.revenue}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div
              className="w-100"
              style={{ marginTop: "3rem", marginLeft: "10rem" }}
            >
              {" "}
              <h4 style={{ color: "#808080" }}>No data found</h4>
            </div>
          )}
        </div>
      </div>

      {/* sub graph */}
      <div
        className="d-flex w-100"
        style={{ justifyContent: "space-between", marginTop: "5rem" }}
      >
        <div className="w-50">
          <h1
            style={{
              fontWeight: "bold",
              fontSize: "2rem",
              color: "black",
              marginBottom: "0px",
            }}
          >
            Revenue generated by source
          </h1>

          <h4
            style={{
              color: "#808080",
              fontSize: "1.1rem",
              marginBottom: "2rem",
            }}
          >{`Data is for past 7 days`}</h4>
          {revenueBySources.length > 0 ? (
            <div className="d-flex w-100">
              <div style={{ width: "80%" }}>
                <table className="table">
                  <thead>
                    <tr>
                      <th style={{ fontWeight: "bold" }}>Source</th>
                      <th
                        style={{ fontWeight: "bold" }}
                      >{`Revenue ( ${currency} )`}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {revenueBySources.map((entry, index) => (
                      <tr key={index}>
                        <td
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {entry.name}
                        </td>
                        <td
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {entry.price}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div
              className="w-100"
              style={{ marginTop: "3rem", marginLeft: "10rem" }}
            >
              {" "}
              <h4 style={{ color: "#808080" }}>No data found</h4>
            </div>
          )}
        </div>
        <div className="w-50">
          <h1
            style={{
              fontWeight: "bold",
              fontSize: "2rem",
              color: "black",
              marginBottom: "0px",
            }}
          >
            Revenue generated by roomtype
          </h1>

          <h4
            style={{
              color: "#808080",
              fontSize: "1.1rem",
              marginBottom: "2rem",
            }}
          >{`Data is for past 7 days `}</h4>
          {revenueByRoomTypes.length > 0 ? (
            <div className="d-flex w-100">
              <div style={{ width: "90%" }}>
                <table className="table">
                  <thead>
                    <tr>
                      <th style={{ fontWeight: "bold" }}>Roomtype</th>
                      <th
                        style={{ fontWeight: "bold" }}
                      >{`Revenue ( ${currency} )`}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {revenueByRoomTypes.map((entry, index) => (
                      <tr key={index}>
                        <td
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {entry.name}
                        </td>
                        <td
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {entry.price}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div
              className="w-100"
              style={{
                marginTop: "3rem",
                marginLeft: "10rem",
              }}
            >
              {" "}
              <h4 style={{ color: "#808080" }}>No data found</h4>
            </div>
          )}
        </div>
      </div>
      {openBlock && (
        <ViewHotels
          open={openBlock}
          modalClose={() => {
            setOpenBlock(false);
          }}
          zipcode={data?.accessControl?.hotel?.hotel_zipcode}
          star={starCategory}
          date={todaysDate}
          currency={currency}
          conversionfactor={conversionfactor}
          hotelCity={hotelCity}
        ></ViewHotels>
      )}
    </div>
  ) : (
    <DashboardMockLoader />
  );
}

const ScaleContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  marginTop: "20px",
  borderRadius: "8px",
  position: "relative",
}));

const Section = styled(Box)(({ theme, color }) => ({
  flex: 1,
  backgroundColor: color,
  height: "5px",
}));

const Marker = styled(Box)(({ value }) => ({
  position: "absolute",
  left: `${value}%`,
  transform: "translateX(-50%)",
  width: "2px",
  height: "5px",
  backgroundColor: "black",
}));

const Tooltip = styled(Box)(({ theme, value, color }) => ({
  position: "absolute",
  left: `${value}%`,
  transform: "translateX(-50%)",
  bottom: "100%", // Position above the scale
  marginBottom: "6px", // Space between the scale and tooltip
  padding: "5px 10px",
  borderRadius: "13px",
  backgroundColor: color,
  color: "black",
  fontSize: "12px",
  whiteSpace: "nowrap",
  border: `1px solid ${color}`,
  fontWeight: "500",
}));
function mapStateToProps(state) {
  return { data: state.auth.selectedHotel, hotelType: state.auth.hotelType };
}
export default connect(mapStateToProps)(RMDashboard);
