/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  IconButton,
  Tooltip,
  tooltipClasses,
  Badge,
  Card,
  CardHeader,
  Collapse,
  CardContent,
  Typography,
  Avatar,
  Divider,
  CardActions,
  Modal,
  Box,
  CircularProgress,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import { styled } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { Dropdown, OverlayTrigger } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { red, green } from "@mui/material/colors";
import PerfectScrollbar from "react-perfect-scrollbar";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { absoluteURL } from "../../../../../app/Utils";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import { BiHelpCircle } from "react-icons/bi";
import { getAllImagesAndUrl } from "../../../../../app/pages/BookingDashboard/apis/BrandingModalApi";
import { getUserWizardRender } from "../../../../../app/pages/AccessControl/apis/AccessControlApi";
import NotificationsIcon from "@mui/icons-material/Notifications";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { updateEnquiryStatus } from "../../../../../app/pages/BookingDashboard/apis/BookingDashboardApi";
import { toast } from "material-react-toastify";
import { makeStyles } from "@material-ui/styles";
import { channelLogoMap } from "../../../../../app/pages/CalendarPage/constants/Calendar-constants";
import noBooking from "../../../../../app/pages/Folios/ViewManagePayments/not_found.svg";
import {
  configDevice,
  getCsmDetails,
  getWebNotifications,
  markAllAsRead,
  setNotificationOpened,
} from "./apis/NotificationAPI";
import { BiSupport } from "react-icons/bi";
import SupportModal from "./support/SupportModal";
import RedeemIcon from "@mui/icons-material/Redeem";
import ReferalModal from "./support/ReferalModal";
import * as AiIcons from "react-icons/ai";
import {
  djangoHandleDataRequests,
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../../../../app/api";
import FreezeExpence from "../../../../../app/Utils/FreezeExpence";
import LockIcon from "@mui/icons-material/Lock";
import { Backdrop, Fade } from "@mui/material";
import Lottie from "react-lottie";
import successAnimationData from "../../../../../app/pages/CalendarPage/Views/lotties/celebration.json";
import { useTranslation } from "react-i18next";
import i18n from "../../../../../app/modules/Auth/pages/i18n";
import GTranslateIcon from "@mui/icons-material/GTranslate";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

const envType = process.env.REACT_APP_ENV;
const CustomBackdrop = ({ style }) => {
  return (
    <Backdrop
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.2) !important",
        ...style,
      }}
    />
  );
};

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#000",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: "0px 0px 15px 4px #0000001a",
    maxWidth: 450,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid transparent",
    marginRight: "1rem",
  },
}));
const useStyles = makeStyles((theme) => ({
  action: {
    marginTop: "0.5rem",
  },
}));

const MyCartDropdown = (props) => {
  //function to get the calendar constant source logo
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(props.selectedLanguage);
  }, [props.selectedLanguage]);

  const [selectedLanguage, setselectedLanguage] = useState(
    localStorage.getItem("lang") ? localStorage.getItem("lang") : "en"
  );
  useEffect(() => {
    localStorage.setItem("lang", selectedLanguage);
  }, [selectedLanguage]);

  const getSource = (notification) => {
    const notifBody = JSON.parse(notification?.notif_body);
    let source = notifBody?.component_body_object?.booking_source;

    if (source === undefined) {
      const notifTitle = notifBody?.title;
      source = notifTitle.split(" ")[1];
    }

    if (source !== undefined) {
      if (channelLogoMap[source] === undefined) return "";
      return channelLogoMap[source];
    }
  };

  //CSM DETAILS for Support Modal
  const [csmData, setCsmData] = useState(null);
  useEffect(async () => {
    const groupId = props?.data?.accessControl?.hotel?.group_id;
    if (groupId && csmData == null) {
      await getCsmDetails(groupId).then((resp) => {
        if (String(resp?.status) === "200") {
          const data = resp?.data;
          setCsmData(data);
        }
      });
    }
  }, [props?.data?.accessControl?.hotel?.group_id]);

  const url = new URL(window.location.href);
  const hotelId = url.searchParams.get("hotelId");

  const [allNotifications, setAllNotifications] = useState([]);
  const [whiteLabelled, setWhiteLabelled] = useState("ENABLED");
  const [support, setSupport] = useState(false);
  const bgImage = toAbsoluteUrl("/media/misc/bg-1.jpg");
  const [cart, setCart] = useState(JSON.parse(localStorage.getItem("cart")));

  const hotelTnC = localStorage.getItem("hotel_tnc");
  /* Opens the Support Modal */
  const openSupport = () => {
    setSupport(true);
  };

  /* Closes the Support Modal */
  const hideSupport = () => {
    setSupport(false);
  };
  const [referalModal, setreferalModal] = useState(false);
  const openReferalModal = () => {
    setreferalModal(true);
  };

  /* Closes the Support Modal */
  const hideReferalModal = () => {
    setreferalModal(false);
  };

  const [translatorModal, setTranslatorModal] = useState(false);
  const openTranslatorModal = () => {
    setTranslatorModal(true);
  };

  const hideTranslatorModal = () => {
    setTranslatorModal(false);
  };

  // useEffect(() => {
  //   if(typeof window != undefined){
  //     if(localStorage.getItem('cart')){
  //         setCart(JSON.parse(localStorage.getItem('cart')));
  //     }
  //   }
  // })

  //to instantiate the firebase and set the event listener
  useEffect(() => {
    if (props?.data?.accessControl?.hotel?.hotel_email) {
      //the background message function

      const bgNotifService = async (event) => {
        //this causes to send background notification only when the pms tab is open/in background
        navigator.serviceWorker.ready.then((registration) => {
          const notificationData = event?.data?.notification;
          const notificationTitle = notificationData?.title;
          const notificationOptions = {
            body: notificationData?.body,
          };
          registration.showNotification(notificationTitle, notificationOptions);
        });
        setTimeout(function () {
          loadNotifications(); //to add it to the list
        }, 3000);
      };

      //removing any existing event listener
      navigator.serviceWorker.removeEventListener("message", bgNotifService, {
        once: true,
      });

      //To setup the firebase once opened
      const firebaseConfig = {
        apiKey: "AIzaSyBU1Yz9D8mLyTL2kFtrqXLzrLQT3o3nZic",
        authDomain: "stayflexi-web-pms.firebaseapp.com",
        databaseURL: "https://stayflexi-web-pms.firebaseio.com",
        projectId: "stayflexi-web-pms",
        storageBucket: "stayflexi-web-pms.appspot.com",
        messagingSenderId: "781784447530",
        appId: "1:781784447530:web:44e51c299d113eda002153",
        measurementId: "G-9B34J959RB",
      };
      const firebaseApp = initializeApp(firebaseConfig);
      const messaging = getMessaging(firebaseApp);

      getToken(messaging, {
        vapidKey:
          "BOpjCtL-mlNLLxsHh51OtjbGAlVajk19F3mekCK_i2Sd3iKZkCNA_3Eq2UVzn_IzFZ-yng5gujeDByC7RhrFQxg",
      })
        .then((currentToken) => {
          if (currentToken) {
            // Send the token to your server and update the UI if necessary
            // ...
            //console.log(currentToken);
            persistToken(currentToken);
          } else {
            // Show permission request UI
            console.log(
              "No registration token available. Request permission to generate one."
            );
            // ...
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
          // ...
        });

      onMessage(messaging, (payload) => {
        sendNotification(payload); //to send the booking notification
        setTimeout(function () {
          loadNotifications(); //to add it to the list
        }, 3000);
      });

      //adding the listener
      navigator.serviceWorker.addEventListener("message", bgNotifService, {
        once: true,
      });

      //when the tab is closed to remove the event listener and the service worker at the same time
      window.addEventListener("beforeunload", function (e) {
        this.navigator.serviceWorker.removeEventListener(
          "message",
          bgNotifService,
          {
            once: true,
          }
        );
        //de register the service worker registered to the given url
        if (window.navigator && navigator.serviceWorker) {
          navigator.serviceWorker
            .getRegistrations()
            .then(function (registrations) {
              for (let registration of registrations) {
                registration.unregister();
              }
            });
        }
      });
    }
  }, [props?.data?.accessControl?.hotel?.hotel_email]);

  const [accessControl, setaccessControl] = useState({});
  useEffect(async () => {
    if (
      localStorage.getItem("email") !== null &&
      localStorage.getItem("email") !== undefined
    ) {
      handleSpringDataRequest(
        `core/api/v1/accesscontrol/addUserWizardRender?email=${localStorage.getItem(
          "email"
        )}`
      )
        .then((res) => {
          setaccessControl(res?.user_feature_map);
        })
        .catch((err) => console.error(err));
      // const data = await getUserWizardRender(
      //   localStorage.getItem("hotelId"),
      //   localStorage.getItem("email")
      // );
    }
  }, [localStorage.getItem("email")]);

  const hotelName = props.data.hotelName;
  const [helpToggle, sethelpToggle] = useState(false);
  const groupEmail = props?.data?.accessControl?.hotel?.hotel_email;

  //to send a toast notification if the user is at focus on the tab
  const sendNotification = (payload) => {
    let type_of_toast = "success";
    let data = payload.data;
    let component = data.component;
    let component_body = JSON.parse(data.component_body);
    let hotel_id = component_body.hotel_id;
    let booking_id = component_body.booking_id;
    let notification = payload.notification;
    let title = notification.title;
    let notification_body = notification.body;

    toast.success(title + "\n" + notification_body);
  };

  //firebase configuration calls
  const persistToken = async (token) => {
    const url = new URL(window.location.href);
    const hotelId = url.searchParams.get("hotelId");
    await configDevice({
      hotelId: hotelId,
      token: token,
      email: groupEmail,
    });
  };

  //to get the web notifications
  const loadNotifications = async () => {
    const url = new URL(window.location.href);
    const hotelId = url.searchParams.get("hotelId");
    await getWebNotifications(hotelId).then((resp) => {
      if (String(resp?.status) == "200") {
        setAllNotifications(resp.data);
      }
    });
  };

  //to update state on notification change
  useEffect(() => {
    // console.log(
    //   "updating load notifications data length: " + allNotifications.length
    // );
  }, [allNotifications, whiteLabelled]);

  // const initilizeWidget = () => {
  //   function initFreshChat() {
  //     window.fcWidget.init({
  //       token: "15ae2c70-2954-40bc-b456-4c0b35e7b32f",
  //       host: "https://wchat.freshchat.com",
  //     });
  //   }

  //   function initialize(i, t) {
  //     let e;
  //     const data = i.getElementById(t)
  //       ? initFreshChat()
  //       : (((e = i.createElement("script")).id = t),
  //         (e.async = !0),
  //         (e.src = "https://wchat.freshchat.com/js/widget.js"),
  //         (e.onload = initFreshChat),
  //         i.head.appendChild(e));
  //   }

  //   function initiateCall() {
  //     initialize(document, "Freshchat-js-sdk");
  //   }

  //   window.addEventListener
  //     ? window.addEventListener("load", initiateCall, !1)
  //     : window.attachEvent("load", initiateCall, !1);

  //   // Copy the below lines under window.fcWidget.init inside initFreshChat function in the above snippet

  //   // To set unique user id in your system when it is available
  //   window.fcWidget.setExternalId(props.data.hotelId);

  //   window.fcWidget.user.setFirstName(
  //     props.data.hotelName + "[" + props.data.hotelId + "]"
  //   );

  //   window.fcWidget.user.setEmail(props.data.email);
  //   // window.fcWidget.open();
  //   // window.fcWidget.close();
  //   window.fcWidget.hide();
  // };
  // initilizeWidget();

  // useEffect(() => {
  //   if (helpToggle === true) {
  //     window.fcWidget.open();
  //     window.fcWidget.show();
  //   } else if (helpToggle === false) {
  //     window.fcWidget.hide();
  //     window.fcWidget.close();
  //   }
  // }, [helpToggle]);

  const [userBrandingData, setuserBrandingData] = useState();
  const userBrandingSatus = async () => {
    try {
      const data = await getAllImagesAndUrl();
      setuserBrandingData(data);
      if (data?.status === null || data?.status === undefined) {
        setWhiteLabelled("DISABLED");
      }
    } catch (e) {
      console.error(e);
    }
  };
  const classes = useStyles();

  //on close icon click or notification click
  const handleNotifClick = async (notifId) => {
    let newNotifications = allNotifications.filter(
      (notification) => notification.id != notifId
    );
    setAllNotifications(newNotifications);
    await setNotificationOpened(hotelId, notifId);
  };

  //on mark as all read href click
  const handleMarkAllasRead = async () => {
    const notifArr = [];
    for (let i = 0; i < allNotifications?.length; i++) {
      notifArr.push(allNotifications[i].id);
    }
    const data = { hotelId: hotelId };
    await markAllAsRead({ hotelId: hotelId, notificationIds: notifArr }).then(
      (resp) => {
        if (String(resp) == "success") {
          setAllNotifications([]);
        }
      }
    );
  };

  //to initial load the notifications
  useEffect(async () => {
    await userBrandingSatus();
    await loadNotifications();
  }, []);
  const [email, setemail] = useState("");
  useEffect(() => {
    setemail(localStorage.getItem("email"));
  }, [localStorage.getItem("email")]);

  //------------expence Modal------------

  const [open, setOpen] = useState(false);
  const [menu, setMenu] = useState("");
  const handleMenuModal = (menu) => {
    setMenu(menu);
    setOpen(true);
  };

  const [confirmModal, setconfirmModal] = useState(false);
  const getId = () => {
    if (menu === "Reputation management") {
      return 27;
    } else if (menu === "Expense Manager") {
      return envType === "BETA" ? 90 : 87;
    } else if (menu === "Stock management") {
      return 227;
    } else if (menu === "Shops") {
      return 16;
    }
  };

  const getImage = () => {
    if (menu === "Reputation management") {
      return "https://storage.googleapis.com/prod-images-bucket/misc/AI-powered%20Reputation%20Management%20(10).png";
    } else if (menu === "Expense Manager") {
      return "https://storage.googleapis.com/prod-images-bucket/misc/3.png";
    } else if (menu === "Stock management") {
      return "https://storage.googleapis.com/prod-images-bucket/misc/1.png";
    } else if (menu === "Shops") {
      return "https://storage.googleapis.com/prod-images-bucket/misc/2.png";
    }
  };

  const getAmount = () => {
    if (
      menu === "Reputation management" &&
      props?.data?.hotelCurrency === "INR"
    ) {
      return 0;
    } else if (
      menu === "Expense Manager" &&
      props?.data?.hotelCurrency === "INR"
    ) {
      return 999;
    } else if (
      menu === "Stock management" &&
      props?.data?.hotelCurrency === "INR"
    ) {
      return 1999;
    } else if (menu === "Shops" && props?.data?.hotelCurrency === "INR") {
      return 1999;
    } else if (
      menu === "Reputation management" &&
      props?.data?.hotelCurrency !== "INR"
    ) {
      return 0;
    } else if (
      menu === "Expense Manager" &&
      props?.data?.hotelCurrency !== "INR"
    ) {
      return 49;
    } else if (
      menu === "Stock management" &&
      props?.data?.hotelCurrency !== "INR"
    ) {
      return 99;
    } else if (menu === "Shops" && props?.data?.hotelCurrency !== "INR") {
      return 99;
    }
  };
  const [paymentLoader, setpaymentLoader] = useState(false);

  const startTrial = () => {
    setpaymentLoader(true);
    const payload = {
      hotelId: hotelId,
      groupId: localStorage.getItem("mainGroupId"),
      description: `${menu} upgrade`,
      type: "ADDON",
      amount: getAmount(),
      addon_feature_id: getId(),
    };

    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/feature-upgrade/single-feature-upgrade`,
      payload
    )
      .then(() => {
        setpaymentLoader(false);
        setOpen(false);
        setconfirmModal(true);
      })
      .catch((err) => {
        toast.error(err.response.data);
        setpaymentLoader(false);
      });
  };

  const getText = () => {
    if (
      menu === "Reputation management" &&
      props?.data?.hotelCurrency === "INR"
    ) {
      return (
        <div>
          {`${t("Upgrade for INR")} `}
          <span style={{ textDecoration: "line-through" }}>999</span> 0 /{" "}
          {` ${t("month")}`}
        </div>
      );
    } else if (
      menu === "Expense Manager" &&
      props?.data?.hotelCurrency === "INR"
    ) {
      return (
        <div>
          {`${t("Upgrade for INR")} `}
          <span style={{ textDecoration: "line-through" }}>3000</span> 999 /
          {` ${t("month")}`}
        </div>
      );
    } else if (
      menu === "Stock management" &&
      props?.data?.hotelCurrency === "INR"
    ) {
      return (
        <div>
          {`${t("Upgrade for INR")} `}
          <span style={{ textDecoration: "line-through" }}>5000</span> 1999 /
          {` ${t("month")}`}
        </div>
      );
    } else if (menu === "Shops" && props?.data?.hotelCurrency === "INR") {
      return (
        <div>
          {`${t("Upgrade for INR")} `}
          <span style={{ textDecoration: "line-through" }}>6000</span> 1999 /
          {` ${t("month")}`}
        </div>
      );
    } else if (
      menu === "Reputation management" &&
      props?.data?.hotelCurrency !== "INR"
    ) {
      return (
        <div>
          {`${t("Upgrade for")} `}
          <span style={{ textDecoration: "line-through" }}>$49</span> $0 /{" "}
          {` ${t("month")}`}
        </div>
      );
    } else if (
      menu === "Expense Manager" &&
      props?.data?.hotelCurrency !== "INR"
    ) {
      return (
        <div>
          {`${t("Upgrade for")} `}
          <span style={{ textDecoration: "line-through" }}>$299</span> $49 /
          {` ${t("month")}`}
        </div>
      );
    } else if (
      menu === "Stock management" &&
      props?.data?.hotelCurrency !== "INR"
    ) {
      return (
        <div>
          {`${t("Upgrade for")} `}
          <span style={{ textDecoration: "line-through" }}>$499</span> $99 /
          {` ${t("month")}`}
        </div>
      );
    } else if (menu === "Shops" && props?.data?.hotelCurrency !== "INR") {
      return (
        <div>
          {`${t("Upgrade for")} `}
          <span style={{ textDecoration: "line-through" }}>$399</span> $99 /
          {` ${t("month")}`}
        </div>
      );
    }
  };

  const successAnimation = {
    loop: true,
    autoplay: true,
    animationData: successAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div
      className="d-flex align-items-center"
      // style={{ height: "450px", overflowY: "scroll" }}
    >
      {/* {email !== "implementation@skypalaceinn.com" &&
        props.hotelType !== "ISHA" &&
        String(whiteLabelled) === "DISABLED" &&
        window.screen.width > 1400 && (
          <p
            className="mb-0"
            style={{
              fontSize: "16px",
              color: "gray",
              marginTop: "5px",
            }}
          >
            Refer and earn
          </p>
        )} */}
      {/* {Object.entries(accessControl).length > 0 &&
        JSON.parse(accessControl?.LanguageTranslator).read === "YES" && (
          <div
            style={{
              marginTop: "1.2rem",
              marginRight: "1rem",
              marginBottom: "1rem",
            }}
          >
            <Tooltip
              title={
                <Typography
                  sx={{
                    fontSize: "16px",
                  }}
                >
                  {`${t("Language translator")}`}
                </Typography>
              }
              arrow
            >
              <IconButton onClick={(event) => openTranslatorModal()}>
                <GTranslateIcon size="25" color="#1BC5BD" />
              </IconButton>
            </Tooltip>

            <ReferalModal
              show={referalModal}
              hide={hideReferalModal}
            ></ReferalModal>
          </div>
        )} */}
      {/* {groupEmail !== "implementation@skypalaceinn.com" &&
        groupEmail !== "reservation@maharashtratourism.gov.in" &&
        props.hotelType !== "ISHA" &&
        String(whiteLabelled) === "DISABLED" && (
          <div
            style={{
              marginTop: "1.2rem",
              marginRight: "1rem",
              marginBottom: "1rem",
            }}
          >
            <Tooltip
              title={
                <Typography
                  sx={{
                    fontSize: "16px",
                  }}
                >
                  {`${t("Referral")}`}
                </Typography>
              }
              arrow
            >
              <IconButton onClick={(event) => openReferalModal()}>
                <AiIcons.AiFillGift size="25" color="#1BC5BD" />
              </IconButton>
            </Tooltip>

            <ReferalModal
              show={referalModal}
              hide={hideReferalModal}
            ></ReferalModal>
          </div>
        )} */}
      {/* <div
        style={{
          marginTop: "1.2rem",
          marginRight: "1rem",
          marginBottom: "1rem",
        }}
      >
        <Dropdown drop="down" style={{ zIndex: 1000 }}>
          <Dropdown.Toggle
            id="dropdown-toggle-my-cart-toggle"
            as={DropdownTopbarItemToggler}
          >
            <Tooltip
              title={
                <Typography
                  sx={{
                    fontSize: "18px",
                  }}
                >
                  {`${t("Notification")}`}
                </Typography>
              }
              arrow
            >
              <IconButton>
                <Badge
                  color="primary"
                  invisible={false}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  badgeContent={allNotifications.length}
                >
                  <NotificationsIcon fontSize="large" />
                </Badge>
              </IconButton>
            </Tooltip>
          </Dropdown.Toggle>

          <Dropdown.Menu
            className="p-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-lg panelWrapper"
            style={{
              width: "35rem",
              whiteSpace: "normal",
              minHeight: "45rem",
              maxHeight: "45rem",
              overflowY: "auto",
            }}
          >
            <Dropdown.Header
              style={{
                fontSize: "1.5rem",
                color: "black",
                alignItems: "center",
              }}
            >
              {`${t("Notifications")}`}

              {allNotifications?.length > 0 ? (
                <div
                  style={{
                    fontSize: "1rem",
                    float: "right",
                    marginTop: "7px",
                    color: "#1BC5BD",
                  }}
                  onClick={(event) => handleMarkAllasRead()}
                >
                  <a> {`${t("Mark all as read")}`}</a>
                </div>
              ) : (
                <></>
              )}
            </Dropdown.Header>
            <Dropdown.Divider />
            {allNotifications?.length === 0 ? (
              <Dropdown.Item
                style={{
                  whiteSpace: "normal",
                  paddingTop: "10rem",
                  paddingBottom: "20rem",
                }}
              >
                <div
                  className="d-flex flex-column align-items-center"
                  style={{ marginTop: "0rem" }}
                >
                  <img style={{ width: "45%" }} src={noBooking} alt="" />
                  <p className="mt-4">
                    You have read all your notifications from the last 30 days
                  </p>
                </div>
              </Dropdown.Item>
            ) : (
              <></>
            )}
            {allNotifications?.length > 0 &&
              allNotifications.map((notification) => (
                <Dropdown.Item
                  style={{
                    whiteSpace: "normal",
                    margin: "0px",
                    padding: "0px",
                    paddingTop: "5px",
                    width: "100%",
                  }}
                >
                  <CardHeader
                    style={{
                      paddingLeft: "16px",
                      paddingRight: "16px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      width: "100%",
                    }}
                    titleTypographyProps={{ fontSize: "1.2rem" }}
                    subheaderTypographyProps={{
                      fontSize: "0.9rem",
                      color: "#616161",
                    }}
                    avatar={
                      notification?.notif_component === "BOOKING" &&
                      getSource(notification) != "" ? (
                        <Avatar
                          src={getSource(notification)}
                          aria-label="recipe"
                        />
                      ) : (
                        <Avatar
                          style={{ backgroundColor: "#1BC5BD" }}
                          aria-label="recipe"
                        >
                          {notification.notif_component === "BOOKING"
                            ? "BK"
                            : "HK"}
                        </Avatar>
                      )
                    }
                    title={
                      notification.notif_component === "BOOKING" ? (
                        <Link
                          style={{ color: "#616161" }}
                          to={{
                            pathname: `/folio/${
                              JSON.parse(notification?.notif_body)
                                ?.component_body_object.booking_id
                            }`,
                            search: `?hotelId=${hotelId}`,
                          }}
                          onClick={(event) => handleNotifClick(notification.id)}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            {JSON.parse(notification.notif_body).title}
                          </span>
                          <span style={{ fontSize: "0.8rem" }}>
                            {String(
                              " " +
                                moment(
                                  notification.created_time,
                                  "DD-MM-YYYY HH:mm:ss"
                                ).fromNow()
                            )}
                          </span>
                        </Link>
                      ) : (
                        <Link
                          style={{ color: "#616161" }}
                          to={{
                            pathname: `/housekeeping`,
                            search: `?hotelId=${hotelId}`,
                          }}
                          onClick={(event) => handleNotifClick(notification.id)}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            {JSON.parse(notification.notif_body).title}
                          </span>
                          <span style={{ fontSize: "0.8rem" }}>
                            {String(
                              " " +
                                moment(
                                  notification.created_time,
                                  "DD-MM-YYYY HH:mm:ss"
                                ).fromNow()
                            )}
                          </span>
                        </Link>
                      )
                    }
                    subheader={
                      JSON.parse(notification.notif_body).component_body_text
                    }
                    action={
                      <IconButton>
                        <CloseIcon
                          onClick={(event) => handleNotifClick(notification.id)}
                        />
                      </IconButton>
                    }
                  ></CardHeader>
                </Dropdown.Item>
              ))}
          </Dropdown.Menu>
        </Dropdown>
      </div> */}
      <div
        style={{
          marginTop: "1.2rem",
          marginRight: "1rem",
          marginBottom: "1rem",
        }}
      >
        {props?.hotelType === "ISHA" ? (
          <div className="d-flex">
            {/* <Tooltip
              title={
                <Typography
                  sx={{
                    fontSize: "16px",
                  }}
                >
                  {`${t("Support")}`}
                </Typography>
              }
              arrow
            > */}
            <h4
              style={{
                color: "#36454F",
                marginBottom: "0px",
                cursor: "pointer",
                textDecoration: "underline",
              }}
              onClick={(event) => openSupport()}
            >
              help?
            </h4>

            {/* <IconButton onClick={(event) => openSupport()}>
                <BiSupport size="25" color="#36454F" />
              </IconButton> */}
            {/* </Tooltip> */}
          </div>
        ) : (
          <>
            {groupEmail === "implementation@skypalaceinn.com" ||
            groupEmail === "reservation@maharashtratourism.gov.in" ? (
              <></>
            ) : (
              <div>
                {String(whiteLabelled) === "DISABLED" ? (
                  <div className="d-flex">
                    {/* <Tooltip
                      title={
                        <Typography
                          sx={{
                            fontSize: "16px",
                          }}
                        >
                          {`${t("Support")}`}
                        </Typography>
                      }
                      arrow
                    > */}
                    <h4
                      style={{
                        color: "#36454F",
                        marginBottom: "0px",
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                      onClick={(event) => openSupport()}
                    >
                      help?
                    </h4>
                    {/* <IconButton onClick={(event) => openSupport()}>
                        <BiSupport size="25" color="#36454F" />
                      </IconButton> */}
                    {/* </Tooltip> */}
                  </div>
                ) : (
                  <>
                    {csmData?.whitelabel_support_options === "ENABLED" ? (
                      <div className="d-flex">
                        {/* <Tooltip title="Support" arrow> */}
                        <h4
                          style={{
                            color: "#36454F",
                            marginBottom: "0px",
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                          onClick={(event) => openSupport()}
                        >
                          help?
                        </h4>
                        {/* <IconButton onClick={(event) => openSupport()}>
                            <BiSupport size="25" color="#1BC5BD" />
                          </IconButton> */}
                        {/* </Tooltip> */}
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </div>
            )}
          </>
        )}
        <SupportModal
          show={support}
          hide={hideSupport}
          csmData={csmData}
          currency={props?.data?.hotelCurrency}
        ></SupportModal>
      </div>
      {/* {props.hotelType === "ISHA" && (
        <div
          style={{
            marginTop: "1.2rem",
            marginRight: "1rem",
            marginBottom: "1rem",
          }}
        >
          <Tooltip title="Support" arrow>
            <IconButton onClick={(event) => openSupport()}>
              <BiSupport size="25" color="#1BC5BD" />
            </IconButton>
          </Tooltip>

          <SupportModal
            show={support}
            hide={hideSupport}
            csmData={csmData}
          ></SupportModal>
        </div>
      )} */}
      <Tooltip
        title={
          <Typography
            sx={{
              fontSize: "16px",
            }}
          >
            {`${hotelName}`}
          </Typography>
        }
      >
        <p
          style={{
            marginTop: "1.5rem",
            fontSize: "15px",
            color: "gray",
            overflow: "hidden",
            maxWidth: "200px",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            marginRight: "1rem",
          }}
        >
          {hotelName}
        </p>
      </Tooltip>
      {/* {userBrandingData?.status !== "ENABLED" && (
        <Tooltip title="Help">
          <IconButton>
            <BiHelpCircle
              style={{ width: "26px", height: "26px", cursor: "pointer" }}
              onClick={() => {
                sethelpToggle(!helpToggle);
              }}
            />
          </IconButton>
        </Tooltip>
      )} */}
      {/* {props?.data?.hotelCurrency === "INR" && (
        <HtmlTooltip
          title={
            <>
              <img
                style={{ width: "100%" }}
                src={toAbsoluteUrl("/media/dashboard/refer_and_earn_.png")}
                alt=""
              />
            </>
          }
          arrow
        >
          <Button variant="light" className="mr-1">
            {" "}
            Refer {"&"} Earn
          </Button>
        </HtmlTooltip>
      )} */}
      <Dropdown
        drop="down"
        style={{
          zIndex: "1000",
        }}
      >
        <Dropdown.Toggle
          as={DropdownTopbarItemToggler}
          id="dropdown-toggle-my-cart-toggle"
        >
          {/* <OverlayTrigger
          placement="left"
          overlay={<Tooltip id="mycart-panel-tooltip">User panel</Tooltip>}
        > */}

          <Tooltip
            title={
              <Typography
                sx={{
                  fontSize: "16px",
                }}
              >
                {`${t("More apps")}`}
              </Typography>
            }
            arrow
          >
            <div className="btn btn-icon btn-clean btn-lg mr-1">
              <span className="svg-icon svg-icon-xl svg-icon-primary">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Layout/Layout-4-blocks.svg"
                  )}
                  style={{
                    filter:
                      "brightness(0) saturate(100%) invert(27%) sepia(18%) saturate(533%) hue-rotate(158deg) brightness(94%) contrast(86%)",
                  }}
                />
              </span>
            </div>
          </Tooltip>
          {/* </OverlayTrigger> */}
        </Dropdown.Toggle>

        {Object.entries(accessControl).length > 0 && (
          <Dropdown.Menu
            className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-lg panelWrapper scrollbar"
            style={{
              height: "530px",
              // overflowY: "scroll",
            }}
          >
            <div className="panelMembers">
              {JSON.parse(accessControl?.HouseKeeping).read === "YES" && (
                <Dropdown.Item
                  as={Link}
                  to={{
                    pathname: "/housekeeping",
                    search: `?hotelId=${hotelId}`,
                  }}
                >
                  <div className="memberWrapper">
                    <img
                      src={toAbsoluteUrl("/media/quickPanel/housekeeping.png")}
                      className="memberImg"
                      style={{
                        width: "25px",
                        height: "25px",
                        filter:
                          "brightness(0) saturate(100%) invert(27%) sepia(18%) saturate(533%) hue-rotate(158deg) brightness(94%) contrast(86%)",
                      }}
                    />

                    <div
                      className="memberName"
                      style={{ whiteSpace: "normal" }}
                    >
                      {`${t("Housekeeping")}`}
                    </div>
                  </div>
                </Dropdown.Item>
              )}
              {JSON.parse(accessControl?.Reports).read === "YES" && (
                <Dropdown.Item
                  as={Link}
                  to={{
                    pathname: "/reports",
                    search: `?hotelId=${hotelId}`,
                    state: { parentindex: 0 },
                  }}
                >
                  <div className="memberWrapper">
                    <img
                      src={toAbsoluteUrl("/media/quickPanel/report.png")}
                      className="memberImg"
                      style={{
                        width: "25px",
                        height: "25px",
                        filter:
                          "brightness(0) saturate(100%) invert(27%) sepia(18%) saturate(533%) hue-rotate(158deg) brightness(94%) contrast(86%)",
                      }}
                    />
                    <div
                      className="memberName"
                      style={{ whiteSpace: "normal" }}
                    >
                      {`${t("Reports")}`}
                    </div>
                  </div>
                </Dropdown.Item>
              )}

              {JSON.parse(accessControl?.Shops).read === "YES" ? (
                <Dropdown.Item
                  as={Link}
                  to={{
                    pathname: "/allOutlets",
                    search: `?hotelId=${hotelId}`,
                  }}
                >
                  <div className="memberWrapper">
                    <Badge
                      color="warning"
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      // badgeContent={"Beta"}
                    >
                      <img
                        src={toAbsoluteUrl("/media/quickPanel/shops.png")}
                        className="memberImg"
                        style={{
                          width: "25px",
                          height: "25px",
                          filter:
                            "brightness(0) saturate(100%) invert(27%) sepia(18%) saturate(533%) hue-rotate(158deg) brightness(94%) contrast(86%)",
                        }}
                      />
                    </Badge>
                    <div
                      className="memberName"
                      style={{ whiteSpace: "normal" }}
                    >
                      {`${t("Shops")}`}
                    </div>
                  </div>
                </Dropdown.Item>
              ) : (
                <Dropdown.Item onClick={() => handleMenuModal("Shops")}>
                  <div className="memberWrapper">
                    <Badge
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      badgeContent={<LockIcon style={{ color: "#FFC107" }} />}
                    >
                      <img
                        src={toAbsoluteUrl("/media/quickPanel/shops.png")}
                        className="memberImg"
                        style={{
                          width: "25px",
                          height: "25px",
                          filter:
                            "brightness(0) saturate(100%) invert(27%) sepia(18%) saturate(533%) hue-rotate(158deg) brightness(94%) contrast(86%)",
                        }}
                      />
                    </Badge>
                    <div
                      className="memberName"
                      style={{ whiteSpace: "normal" }}
                    >
                      {`${t("Shops")}`}
                    </div>
                  </div>
                </Dropdown.Item>
              )}

              {JSON.parse(accessControl?.CorporateTravelAgent).read ===
                "YES" && (
                <Dropdown.Item
                  as={Link}
                  to={{
                    pathname: "/directBilling",
                    search: `?hotelId=${hotelId}`,
                  }}
                >
                  <div className="memberWrapper">
                    <img
                      src={toAbsoluteUrl("/media/quickPanel/shops.png")}
                      className="memberImg"
                      style={{
                        width: "25px",
                        height: "25px",
                        filter:
                          "brightness(0) saturate(100%) invert(27%) sepia(18%) saturate(533%) hue-rotate(158deg) brightness(94%) contrast(86%)",
                      }}
                    />
                    <div
                      className="memberName"
                      style={{ whiteSpace: "normal" }}
                    >
                      {`${t("Direct billing")}`}
                    </div>
                  </div>
                </Dropdown.Item>
              )}

              {JSON.parse(accessControl?.RMMainMenu).read === "YES" && (
                <Dropdown.Item
                  as={Link}
                  to={{
                    pathname: "/revenue-dashboard",
                    search: `?hotelId=${hotelId}`,
                  }}
                >
                  <div className="memberWrapper">
                    <img
                      src={toAbsoluteUrl(
                        "/media/quickPanel/revenueManagement.png"
                      )}
                      className="memberImg"
                      style={{
                        width: "25px",
                        height: "25px",
                        filter:
                          "brightness(0) saturate(100%) invert(27%) sepia(18%) saturate(533%) hue-rotate(158deg) brightness(94%) contrast(86%)",
                      }}
                    />
                    <div
                      className="memberName"
                      style={{ whiteSpace: "normal" }}
                    >
                      {`${t("Revenue management")}`}
                    </div>
                  </div>
                </Dropdown.Item>
              )}

              {JSON.parse(accessControl?.ExpenseManager).read === "YES" ? (
                <Dropdown.Item
                  as={Link}
                  to={{
                    pathname: "/expense-dashboard",
                    search: `?hotelId=${hotelId}`,
                  }}
                >
                  <div className="memberWrapper">
                    <img
                      src={toAbsoluteUrl("/media/quickPanel/folios.png")}
                      className="memberImg"
                      style={{
                        width: "25px",
                        height: "25px",
                        filter:
                          "brightness(0) saturate(100%) invert(27%) sepia(18%) saturate(533%) hue-rotate(158deg) brightness(94%) contrast(86%)",
                      }}
                    />
                    <div
                      className="memberName"
                      style={{ whiteSpace: "normal" }}
                    >
                      {`${t("Expense Manager")}`}
                    </div>
                  </div>
                </Dropdown.Item>
              ) : (
                <Dropdown.Item
                  onClick={() => handleMenuModal("Expense Manager")}
                >
                  <div className="memberWrapper">
                    <Badge
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      badgeContent={<LockIcon style={{ color: "#FFC107" }} />}
                    >
                      <img
                        src={toAbsoluteUrl("/media/quickPanel/folios.png")}
                        className="memberImg"
                        style={{
                          width: "25px",
                          height: "25px",
                          filter:
                            "brightness(0) saturate(100%) invert(27%) sepia(18%) saturate(533%) hue-rotate(158deg) brightness(94%) contrast(86%)",
                        }}
                      />
                    </Badge>
                    {/* <img
                      src={toAbsoluteUrl("/media/quickPanel/folios.png")}
                      className="memberImg"
                      style={{ width: "25px", height: "25px" }}
                    /> */}
                    <div
                      className="memberName"
                      style={{ whiteSpace: "normal" }}
                    >
                      {`${t("Expense Manager")}`}
                    </div>
                  </div>
                </Dropdown.Item>
              )}
              {JSON.parse(accessControl?.StockManagement).read === "YES" ? (
                <Dropdown.Item
                  as={Link}
                  to={{
                    pathname: "/stock/stock-dashboard",
                    search: `?hotelId=${hotelId}`,
                    state: { parentindex: 7 },
                  }}
                >
                  <div className="memberWrapper">
                    <Badge
                      color="warning"
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      badgeContent={"Beta"}
                    >
                      <img
                        src={toAbsoluteUrl("/media/quickPanel/report.png")}
                        className="memberImg"
                        style={{
                          width: "25px",
                          height: "25px",
                          filter:
                            "brightness(0) saturate(100%) invert(27%) sepia(18%) saturate(533%) hue-rotate(158deg) brightness(94%) contrast(86%)",
                        }}
                      />
                    </Badge>
                    <div
                      className="memberName"
                      style={{ whiteSpace: "normal" }}
                    >
                      {`${t("Stock management")}`}
                    </div>
                  </div>
                </Dropdown.Item>
              ) : (
                <Dropdown.Item
                  onClick={() => handleMenuModal("Stock management")}
                >
                  <div className="memberWrapper">
                    <Badge
                      // color="warning"
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      // badgeContent={"Beta"}
                      badgeContent={<LockIcon style={{ color: "#FFC107" }} />}
                    >
                      <img
                        src={toAbsoluteUrl("/media/quickPanel/report.png")}
                        className="memberImg"
                        style={{
                          width: "25px",
                          height: "25px",
                          filter:
                            "brightness(0) saturate(100%) invert(27%) sepia(18%) saturate(533%) hue-rotate(158deg) brightness(94%) contrast(86%)",
                        }}
                      />
                    </Badge>
                    <div
                      className="memberName"
                      style={{ whiteSpace: "normal" }}
                    >
                      {`${t("Stock management")}`}
                    </div>
                  </div>
                </Dropdown.Item>
              )}

              {/* <Dropdown.Item
                as={Link}
                to={{
                  pathname: "/inbox",
                  search: `?hotelId=${hotelId}`,
                }}
              >
                <div className="memberWrapper">
                  <img
                    src={toAbsoluteUrl("/media/quickPanel/folios.png")}
                    className="memberImg"
                    style={{ width: "25px", height: "25px" }}
                  />
                  <div className="memberName" style={{ whiteSpace: "normal" }}>
                    Chat
                  </div>
                </div>
              </Dropdown.Item> */}

              {/* {props?.data?.accessControl?.accessUser?.accessmap
              ?.ExpenseManager === true && ( */}
              {JSON.parse(accessControl?.ReputationManagement).read === "YES" &&
              String(whiteLabelled) === "DISABLED" ? (
                <Dropdown.Item
                  as={Link}
                  to={{
                    pathname: "/reputation-dashboard",
                    search: `?hotelId=${hotelId}`,
                  }}
                >
                  <div className="memberWrapper">
                    <img
                      src={toAbsoluteUrl("/media/quickPanel/folios.png")}
                      className="memberImg"
                      style={{
                        width: "25px",
                        height: "25px",
                        filter:
                          "brightness(0) saturate(100%) invert(27%) sepia(18%) saturate(533%) hue-rotate(158deg) brightness(94%) contrast(86%)",
                      }}
                    />
                    <div
                      className="memberName"
                      style={{ whiteSpace: "normal" }}
                    >
                      {`${t("Review management")}`}
                    </div>
                  </div>
                </Dropdown.Item>
              ) : (
                String(whiteLabelled) === "DISABLED" && (
                  <Dropdown.Item
                    onClick={() => handleMenuModal("Reputation management")}
                  >
                    <div className="memberWrapper">
                      <Badge
                        // color="warning"
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        // badgeContent={"Beta"}
                        badgeContent={<LockIcon style={{ color: "#FFC107" }} />}
                      >
                        <img
                          src={toAbsoluteUrl("/media/quickPanel/report.png")}
                          className="memberImg"
                          style={{
                            width: "25px",
                            height: "25px",
                            filter:
                              "brightness(0) saturate(100%) invert(27%) sepia(18%) saturate(533%) hue-rotate(158deg) brightness(94%) contrast(86%)",
                          }}
                        />
                      </Badge>
                      <div
                        className="memberName"
                        style={{ whiteSpace: "normal" }}
                      >
                        {`${t("Review management")}`}
                      </div>
                    </div>
                  </Dropdown.Item>
                )
              )}

              {JSON.parse(accessControl?.PaymentAutomation).read === "YES" && (
                <Dropdown.Item
                  as={Link}
                  to={{
                    pathname: "/payment-configuration",
                    search: `?hotelId=${hotelId}`,
                  }}
                >
                  <div className="memberWrapper">
                    <img
                      src={toAbsoluteUrl("/media/quickPanel/dollar-symbol.png")}
                      className="memberImg"
                      style={{
                        width: "25px",
                        height: "25px",
                        filter:
                          "brightness(0) saturate(100%) invert(27%) sepia(18%) saturate(533%) hue-rotate(158deg) brightness(94%) contrast(86%)",
                      }}
                    />
                    <div
                      className="memberName"
                      style={{ whiteSpace: "normal" }}
                    >
                      {`${t("Payments")}`}
                    </div>
                  </div>
                </Dropdown.Item>
              )}

              {JSON.parse(accessControl?.NightAuditConfiguration).read ===
                "YES" && (
                <Dropdown.Item
                  as={Link}
                  to={{
                    pathname: "/nightAudit",
                    search: `?hotelId=${hotelId}`,
                  }}
                >
                  <div className="memberWrapper">
                    <img
                      src={toAbsoluteUrl("/media/quickPanel/audit.png")}
                      className="memberImg"
                      style={{
                        width: "25px",
                        height: "25px",
                        filter:
                          "brightness(0) saturate(100%) invert(27%) sepia(18%) saturate(533%) hue-rotate(158deg) brightness(94%) contrast(86%)",
                      }}
                    />
                    <div
                      className="memberName"
                      style={{ whiteSpace: "normal" }}
                    >
                      {`${t("Night Audit")}`}
                    </div>
                  </div>
                </Dropdown.Item>
              )}

              {JSON.parse(accessControl?.PCMainMenu).read === "YES" && (
                <Dropdown.Item
                  as={Link}
                  to={{
                    pathname: "/property-dashboard",
                    search: `?hotelId=${hotelId}`,
                  }}
                >
                  <div className="memberWrapper">
                    <Badge
                      color="error"
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      invisible={!(hotelTnC === undefined || hotelTnC === "")}
                      variant="standard"
                      badgeContent={
                        <Tooltip title="No TnC for property">
                          <span>i</span>
                        </Tooltip>
                      }
                    >
                      <img
                        src={toAbsoluteUrl(
                          "/media/quickPanel/propertyConf.png"
                        )}
                        className="memberImg"
                        style={{
                          width: "25px",
                          height: "25px",
                          filter:
                            "brightness(0) saturate(100%) invert(27%) sepia(18%) saturate(533%) hue-rotate(158deg) brightness(94%) contrast(86%)",
                        }}
                      />
                    </Badge>
                    <div
                      className="memberName"
                      style={{ whiteSpace: "normal" }}
                    >
                      {`${t("Property configuration")}`}
                    </div>
                  </div>
                </Dropdown.Item>
              )}
              {/* )} */}

              {/* <Dropdown.Item as={Link} to={{ pathname: "/folios", search: `?hotelId=${hotelId}` }}><div className="memberWrapper">
            <img src={toAbsoluteUrl("/media/quickPanel/shops.png")} className="memberImg" style={{ width: '25px', height: '25px' }} />
            <div className="memberName" style={{ whiteSpace: "normal" }}>Folios</div>
          </div></Dropdown.Item> */}
            </div>
            {/* <div className="verticalAxis" /> */}
            {/* <div className="panelMembers">
          {props?.data?.accessControl?.accessUser?.accessmap?.CMPlus ===
            true && (
            <Dropdown.Item
              as={Link}
              to={{
                pathname: "/channelMapping",
                search: `?hotelId=${hotelId}`,
              }}
            >
              <div className="memberWrapper">
                <img
                  src={toAbsoluteUrl("/media/quickPanel/channelMapping.png")}
                  className="memberImg"
                  style={{ width: "25px", height: "25px" }}
                />
                <div className="memberName" style={{ whiteSpace: "normal" }}>
                  Channel mapping
                </div>
              </div>
            </Dropdown.Item>
          )}
        </div> */}
          </Dropdown.Menu>
        )}
      </Dropdown>

      {/* <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <FreezeExpence menu={menu} />
      </Modal> */}

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropComponent={CustomBackdrop}
      >
        <Fade in={open}>
          <Box
            className="settleDuesModal"
            style={{
              boxShadow: "0px 0px 25px 10px #0000001a",
              height: "53rem",
              width: "82rem",
            }}
          >
            <div className="settleDuesWrapper">
              <div className="settleDuesHeader">
                <div className="settleDuesHeading">
                  {`${t("Upgrade for new features")}`}
                </div>
                <div className="close" onClick={() => setOpen(false)}>
                  X
                </div>
              </div>

              <div className="d-flex">
                <div
                  className="amountWrapper"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <h1 style={{ marginTop: "2rem" }}>
                    {menu === "Stock management" &&
                      `${t(
                        "Take control of your inventory with Stock management"
                      )}`}
                    {menu === "Expense Manager" &&
                      `${t("Manage all your expenses in single place")}.`}
                    {menu === "Reputation management" &&
                      `${t("AI powered review management")}`}

                    {menu === "Shops" &&
                      `${t("Manage all your services with Built-in Shops")}`}
                  </h1>
                  <h3 style={{ marginTop: "2rem", marginBottom: "2rem" }}>
                    {menu === "Stock management" &&
                      `${t(
                        `A one stop solution for all your inventory management needs`
                      )}. ${t(`Manage consumption of raw materials`)}, ${t(
                        `F And B`
                      )}, ${t(`housekeeping`)}, ${t("etc in single place")}.`}
                    {menu === "Expense Manager" &&
                      `${t(
                        "Upgrade to expenses manager to steamline your property expenses"
                      )}.`}
                    {menu === "Reputation management" &&
                      `${t("Manage all your booking")}.${t(
                        "com and airbnb reviews in one place"
                      )}. Powered by FlexiGPT, you can automatically reply to reviews with guest sentiment analysis."
                      )}`}
                    {menu === "Shops" &&
                      `${t(
                        "Have a restaurant or spa  or any other service we have got you covered Stayflexi POS brings all your F And B and other services management under single platform."
                      )}`}
                  </h3>

                  {menu === "Shops" && (
                    <>
                      <ul>
                        <li>{`${t("F And B outlets")}`}</li>
                        <li>{`${t("Inroom orders")}`}</li>
                        <li>{`${t("Website orders")}`}</li>
                        <li>{`${t("Table orders")}`}</li>
                        <li>{`${t("Mobile orders")}`}</li>
                        <li>{`${t("Unlimited menu items")}`}</li>
                        <li>{`${t("Mobile app")}`}</li>
                      </ul>
                      <div className="d-flex justify-content-center">
                        <span
                          class="badge badge-warning"
                          style={{
                            fontWeight: "bold",
                            height: "4rem",
                            width: "30rem",
                            marginTop: "3rem",
                            fontSize: "20px",
                            lineHeight: "28px",
                          }}
                        >
                          {`${t("Limited time offer")}, ${
                            props?.data?.hotelCurrency === "INR"
                              ? "66.66%"
                              : "75.00%"
                          } ${t("discounted")}`}
                        </span>
                      </div>
                    </>
                  )}
                  {menu === "Stock management" && (
                    <>
                      <ul>
                        <li>{`${t("Raw materials tracking")}`}</li>
                        <li>{`${t("Vendors management")}`}</li>
                        <li>{`${t("Purchase orders")}`}</li>
                        <li>{`${t("Consumption analysis")}`}</li>
                        <li>{`${t("Raw material to consumption mapping")}`}</li>
                      </ul>
                      <div className="d-flex justify-content-center">
                        <span
                          class="badge badge-warning"
                          style={{
                            fontWeight: "bold",
                            height: "4rem",
                            width: "30rem",
                            marginTop: "3rem",
                            fontSize: "20px",
                            lineHeight: "28px",
                          }}
                        >
                          {`${t("Limited time offer")}, ${
                            props?.data?.hotelCurrency === "INR"
                              ? "60.00%"
                              : "80.00%"
                          } ${t("discount")}`}
                        </span>
                      </div>
                    </>
                  )}
                  {menu === "Reputation management" && (
                    <>
                      <ul>
                        <li> {`${t("FlexiGPT AI-powered")}`}</li>
                        <li>{`${t("Booking and Airbnb reviews")}`}</li>
                      </ul>
                      <div className="d-flex justify-content-center">
                        <span
                          class="badge badge-warning"
                          style={{
                            fontWeight: "bold",
                            height: "4rem",
                            width: "30rem",
                            marginTop: "3rem",
                            fontSize: "20px",
                            lineHeight: "28px",
                          }}
                        >
                          {`${t("Limited time offer")}, 100% ${t(
                            "discounted"
                          )}"`}
                        </span>
                      </div>
                    </>
                  )}
                  {menu === "Expense Manager" && (
                    <>
                      <ul>
                        <li>{`${t("Cash counter")}`}</li>
                        <li>{`${t("Expenses tracking")}`}</li>
                        <li> {`${t("Withdrawals")}`}</li>
                        <li>{`${t("Cash topups")}`}</li>
                      </ul>
                      <div
                        className="d-flex justify-content-center"
                        style={{ marginTop: "0px" }}
                      >
                        <span
                          class="badge badge-warning"
                          style={{
                            fontWeight: "bold",
                            height: "4rem",
                            width: "30rem",
                            marginTop: "3rem",
                            fontSize: "20px",
                            lineHeight: "28px",
                          }}
                        >
                          {`${t("Limited time offer")}, ${
                            props?.data?.hotelCurrency === "INR"
                              ? "66.66%"
                              : "83.33%"
                          } ${t("discounted")}`}
                        </span>
                      </div>
                    </>
                  )}
                  <div
                    style={{
                      position: "absolute",
                      bottom: "2rem",
                      width: "100%",
                    }}
                  >
                    <Button
                      variant="custom-button"
                      style={{
                        width: "46%",
                        fontWeight: "bold",
                        height: "4rem",
                        fontSize: "20px",
                      }}
                      onClick={() => {
                        if (
                          props?.data?.accessControl?.ADMINRIGHTS === "ADMIN"
                        ) {
                          startTrial();
                        } else {
                          toast.warning(
                            "Please contact admin to enable this feature"
                          );
                        }
                      }}
                      disabled={paymentLoader}
                    >
                      {paymentLoader ? (
                        <CircularProgress
                          size={15}
                          color="inherit"
                          sx={{ ml: 1.5 }}
                        />
                      ) : (
                        getText()
                      )}
                    </Button>
                    {String(whiteLabelled) === "DISABLED" && (
                      <p
                        style={{
                          fontWeight: "bold",
                          textDecoration: "underline",
                          cursor: "pointer",
                          marginTop: "2rem",
                          color: "#1BC5BD",
                        }}
                        onClick={() =>
                          window.open(
                            Object.entries(csmData).length > 0
                              ? csmData.account_csm_calendar_link
                              : "https://calendly.com/bharath-gowda/30min",
                            "_blank"
                          )
                        }
                      >
                        {`${t("Talk to sales")}`}
                      </p>
                    )}
                  </div>
                </div>
                <div>
                  <img
                    style={{
                      height: "36rem",
                      width: "40rem",
                    }}
                    src={getImage()}
                  />
                </div>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>

      <Modal
        open={confirmModal}
        onClose={() => setconfirmModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropComponent={CustomBackdrop}
      >
        <Fade in={confirmModal}>
          <Box
            className="settleDuesModal"
            style={{
              boxShadow: "0px 0px 25px 10px #0000001a",
              height: "25rem",
              width: "70rem",
            }}
          >
            <div className="settleDuesWrapper">
              <div className="settleDuesHeader">
                <div className="settleDuesHeading">
                  {`${t("Upgrade for new features")}`}
                </div>
                <div className="close" onClick={() => setconfirmModal(false)}>
                  X
                </div>
              </div>

              <div className="d-flex flex-column justify-content-center align-items-center w-100">
                <Lottie options={successAnimation} height={150} width={150} />
                <h1>
                  {`${t(
                    "Successfully upgraded please logout and login to access this feature"
                  )}`}
                </h1>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>

      <Modal
        open={translatorModal}
        onClose={hideTranslatorModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropComponent={CustomBackdrop}
      >
        <Fade in={translatorModal}>
          <Box
            className="settleDuesModal"
            style={{
              boxShadow: "0px 0px 25px 10px #0000001a",
              height: "22rem",
              width: "25rem",
            }}
          >
            <div className="settleDuesWrapper">
              <div className="settleDuesHeader">
                <div className="settleDuesHeading">
                  {`${t("Language translation")}`}
                </div>
                <div className="close" onClick={hideTranslatorModal}>
                  X
                </div>
              </div>

              <div className="d-flex flex-column justify-content-center align-items-center w-100">
                <FormControl
                  fullWidth
                  style={{ width: "16rem", marginTop: "2rem" }}
                >
                  <InputLabel id="demo-simple-select-label">
                    Select language
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={(e) => {
                      setselectedLanguage(e.target.value);
                    }}
                    className="mr-4"
                    label="Select language"
                    value={selectedLanguage}
                  >
                    <MenuItem value={"en"}>{"English"}</MenuItem>
                    <MenuItem value={"es"}>{"Spanish"}</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <Button
                variant="custom-button"
                style={{
                  marginTop: "2rem",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                onClick={() => {
                  setTimeout(function () {
                    window.location.reload();
                  }, 1000);
                }}
              >
                Save
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel, hotelType: state.auth.hotelType };
}
export default connect(mapStateToProps)(MyCartDropdown);
