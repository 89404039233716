import {
  FormControl,
  Grid,
  Grow,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  CircularProgress,
  Button,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  getChannels,
  getRateMultiplier,
  updateOTArateMultiplier,
} from "../api/InventoryApi";
import { connect } from "react-redux";
import { toast } from "material-react-toastify";
import {
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../../api";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    padding: theme.spacing(2),
  },
  textDisplay: {
    fontSize: "12px",
    marginRight: 6,
  },
  subText: {
    fontSize: "14px",
  },
}));

function OtaRateMultiplier(props) {
  console.log("propsss", props);
  const classes = useStyles();
  const [channels, setChannels] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState("");
  const [multiplier, setMultiplier] = useState(0);
  const [loader, setLoader] = useState(false);
  const handleChange = (e) => {
    const value = e.target.value;
    // Allow only numeric values
    const numericValue = value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    setMultiplier(numericValue);
  };
  console.log("channels", channels);
  useEffect(() => {
    getChannels()
      .then((data) => {
        const finalData = data?.filter(
          (elm) => elm !== "walkin" && elm !== "booking engine"
        );
        setChannels(finalData);
        // setSelectedChannel(data?.[0]);
      })
      .catch(() => {});
  }, []);

  const handleSubmit = () => {
    setLoader(true);
    const payload = {
      hotelId: props?.data?.hotelId,
      channelId: selectedChannel,
      multiplier: multiplier,
    };
    handleSpringDataMutationRequest(
      "POST",
      "core/api/v1/inventory/add-ota-multiplier",
      payload
    )
      .then(() => {
        setLoader(false);
        toast.success("OTA rate updated successfully");
        props.closeFunc();
      })
      .catch(() => {
        setLoader(false);
        toast.error("Something went wrong");
      });
  };

  useEffect(() => {
    handleSpringDataRequest(
      `core/api/v1/inventory/get-ota-multiplier?channelId=${selectedChannel}`
    )
      .then((data) => {
        setMultiplier(data);
      })
      .catch(() => {});
  }, [props, selectedChannel]);
  return (
    <Grow in timeout={1000} style={{ transformOrigin: "0 0 0" }}>
      <Grid container>
        {" "}
        <Grid container className={classes.textField} spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Channel</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Channel"
                value={selectedChannel}
                disabled={channels?.length <= 0}
                onChange={(e) => setSelectedChannel(e?.target.value)}
              >
                {channels?.map((item) => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Multiplier"
              variant="outlined"
              value={multiplier}
              onChange={handleChange}
              disabled={!selectedChannel}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="custom-button"
              onClick={handleSubmit}
              disabled={loader || !selectedChannel || !multiplier}
            >
              Submit
              {loader && (
                <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
              )}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grow>
  );
}

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel };
}

export default connect(mapStateToProps)(OtaRateMultiplier);
